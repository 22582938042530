import React from 'react';

import './index.scss';

type ButtonT = {
  name: string;
  action: () => void;
  color: 'primary' | 'secondary' | 'success';
  icon?: JSX.Element;
};
export interface IHeroSectionProps {
  title: string | JSX.Element;
  description?: string | JSX.Element;
  button?: ButtonT | ButtonT[];
  buttonClassName?: string;
}

export default function HeroSection({ title, description, button, buttonClassName = 'block' }: IHeroSectionProps) {
  const renderButton = () => {
    if (Array.isArray(button)) {
      return button.map((btn, index) => (
        <button key={index} className={`ihs__btn ${btn.color}`} onClick={btn.action}>
          {btn.icon}
          {btn.name}
        </button>
      ));
    }
    return (
      <button className={`ihs__btn ${button?.color}`} onClick={button?.action}>
        {button?.icon}
        {button?.name}
      </button>
    );
  };
  return (
    <div className="ihs">
      <div className="ihs__content">
        <h1 className="ihs__title">{title}</h1>
        <p className="ihs__description">{description}</p>
      </div>
      {button && <div className={`ihs__btn-wrapper ${buttonClassName}`}>{renderButton()}</div>}
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { useCopyToClipboard } from 'react-use';

import blueCopyIcon from '+assets/img/dashboard/copy-icon-blue.svg';
import copyIcon from '+assets/img/dashboard/copy-icon-light.svg';

import './index.scss';

interface ICopyablePropsType {
  text: string;
  showOnHover?: boolean;
  copyText?: string;
  copyColor?: string;
  spanClassName?: string;
  buttonClassName?: string;
  imageClassName?: string;
  textModifier?: (text: string) => string;
  isBlueIcon?: boolean;
  textToCopy?: string;
  showText?: boolean;
}
function Copyable({
  text = 'Not Available',
  showOnHover = false,
  copyText,
  copyColor = '#4acfb2',
  spanClassName,
  buttonClassName,
  imageClassName,
  textModifier = x => x,
  isBlueIcon = false,
  textToCopy,
  showText = true
}: ICopyablePropsType) {
  const [copied, setCopied] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [hovered, setHovered] = useState<boolean>(false);
  const [, copyToClipboard] = useCopyToClipboard();

  useEffect(() => {
    setValue(textModifier(text));
  }, [text]);

  const updateFunction = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
      setHovered(false);
      setValue(textModifier(text));
    }, 800);
  };

  return (
    <span
      aria-label={`Copy ${text}`}
      data-testid="copyable-span"
      className={`copyable__comp ${spanClassName}`}
      style={copied ? { color: copyColor } : {}}
      onMouseEnter={() => {
        if (showOnHover) {
          setHovered(true);
          const newValue = `${value.substr(0, value.length - 5)}...`;
          setValue(newValue);
        }
      }}
      onMouseLeave={() => {
        if (showOnHover) {
          setHovered(false);
          setValue(textModifier(text));
        }
      }}
    >
      {copied ? (
        copyText || 'Copied!'
      ) : (
        <>
          {showText ? <span>{value}</span> : ''}
          {(hovered || !showOnHover) && (
            <button
              aria-label="Copy Button"
              data-testid="copyable-button"
              type="button"
              className={`copy-button ${buttonClassName}`}
              style={{ border: 'none' }}
              hidden={text === 'Not Available'}
              onClick={(e) => {
                (() => {
                  e.stopPropagation();
                  copyToClipboard(!textToCopy ? text : textToCopy);
                  updateFunction();
                })();
              }}
            >
              <img
                id={`copy-icon-${text}${Math.floor(Math.random() * 50)}`}
                src={isBlueIcon ? blueCopyIcon : copyIcon}
                alt=""
                className={imageClassName || ''}
              />
            </button>
          )}
        </>
      )}
    </span>
  );
}

export default Copyable;

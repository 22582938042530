import React from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { history } from '+utils';
import { TabKeyType } from './props';
import PayInReversals from './PayInReversals';
import PayoutReversals from './PayoutReversals';
import PayinReversalDetails from './PayinReversalDetails';

const tabs: TabKeyType[] = ['payIns', 'payouts'];

const tabsData: Record<TabKeyType, any> = {
  payIns: {
    label: 'Pay-In',
    tabRoute: '/dashboard/payment-reversals'
  },
  payouts: {
    label: 'Payout',
    tabRoute: '/dashboard/payment-reversals/payout'
  }
};

function ReversalsTabs() {
  const location = useLocation();
  const activeTab = location.pathname.includes('payout') ? 'payouts' : 'payIns';

  return (
    <div className="content-i">
      <div className="content-box">
        <section className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex mb-4">
            <ul className="nav nav-tabs">
              {tabs.map((tabKey) => {
                const { label, tabRoute } = tabsData[tabKey];
                return (
                  <li className="nav-item" key={tabKey}>
                    <button
                      type="button"
                      className={`nav-link ${activeTab === tabKey ? 'active' : ''}`}
                      onClick={() => history.push(tabRoute)}
                    >
                      {label} Reversals
                    </button>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        {activeTab === 'payouts' ? <PayoutReversals /> : <PayInReversals />}
      </div>
    </div>
  );
}

export default function Reversals() {
  return (
    <div>
      <Switch>
        <Route exact path="/dashboard/payment-reversals" component={ReversalsTabs} />
        <Route exact path="/dashboard/payment-reversals/payout" component={ReversalsTabs} />
        <Route path="/dashboard/payment-reversals/:id">
          <PayinReversalDetails />
        </Route>
      </Switch>
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { backwardAmountInput, capitalize, cleanInput, downloadFileViaUrl, getDate, getTime, logError } from '+utils';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import { chargebackHistoryDataT } from '+types';
import Modal, { IModalProps } from '+shared/Modal';
import smallFile from '+assets/img/dashboard/file-small.svg';
import downloadSmall from '+assets/img/dashboard/download-small.svg';
import './index.scss';

type ModalStepT = 'confirm' | 'init' | 'feedback' | 'resolve-chargeback' | 'confirm-chargeback';

type chargebackDetailsModalT = {
  close: () => void;
  type: ModalStepT;
  data: chargebackHistoryDataT[];
  reference: string;
  setModalState: (value: ModalStepT) => void;
};

const chargebackStatusLabel = {
  lost: 'Chargeback Lost',
  won: 'Chargeback Won',
  partial: 'Partially declined'
};

const apiRequest = new APIRequest();
const ChargebackDetailsModal = ({ close, type, data, reference, setModalState }: chargebackDetailsModalT) => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [chargebackState, setChargebackState] = useState('');
  const [formData, setFormData] = useState({
    amount: ''
  });

  const handleDownload = (url: URL) => downloadFileViaUrl(url, 'evidence', 'application/pdf');
  const resolveChargebackMutation = useMutation((value) => apiRequest.updateChargeback(reference, value), {
    onSuccess: () => {
      queryClient.invalidateQueries(`TRANSACTION_DETAILS_${reference}`);
    },
    onError: (error) => {
      logError(error);
      feedbackInit({
        message: `There has been an error adding chargeback`,
        type: 'danger',
        componentLevel: true
      });
    }
  });
  const feedbackHistoryContent = () => {
    return (
      <div className="feedback_history-wrapper">
        <p>Here you will see all comments made on this chargeback.</p>
        {data.map((item) => {
          if (!['pending', 'partial-pending', 'declined'].includes(item.status)) return null;
          return (
            <div className="feedback_history-item" key={item.date}>
              <p>Feedback on Documents</p>
              <p>{capitalize(String(item.reason))}</p>
              <p>{`${getDate(item.date)} ${getTime(item.date)}`}</p>
            </div>
          );
        })}
      </div>
    );
  };

  const chargebackResolveContent = () => {
    return (
      <div className="resolve_chargeback-wrapper">
        {data[0].evidence_link && (
          <div className="chargeback_resolve-defense">
            <p>Merchant Defence</p>
            <p>The customer got full value for this request, see order receipt attached signed by the customer.</p>
            <div className="chargeback_defense-download">
              <span>
                <img src={smallFile} alt="small-file" />
                <p>Evidence.pdf</p>
              </span>
              <span
                role="button"
                tabIndex={0}
                onClick={() => handleDownload(new URL(data[0].evidence_link as URL))}
                onKeyDown={() => handleDownload(new URL(data[0].evidence_link as URL))}
              >
                <img src={downloadSmall} alt="download-file" />
                <p>Download</p>
              </span>
            </div>
          </div>
        )}
        <div className="chargeback_resolve-status">
          <p>Update chargeback status</p>
          <p>
            Declare the chargeback status as won, lost or partial based on your findings after verifying that the merchant&apos;s defense
            holds up.
          </p>
          <div className="chargeback-radio_container">
            {['won', 'lost', 'partial'].map((item: keyof typeof chargebackStatusLabel) => {
              return (
                <label key={item}>
                  <input
                    checked={item === chargebackState}
                    type="radio"
                    onChange={() => setChargebackState(item)}
                    data-testid="refund-type"
                  />
                  {chargebackStatusLabel[item]}
                </label>
              );
            })}
          </div>
          {chargebackState === 'partial' && (
            <div className="form-group chargeback-amount_container">
              <label htmlFor="amount" className="withdraw-label">
                <span className="dark">New Chargeback Amount (NGN)</span>
              </label>

              <input
                type="number"
                className="form-control"
                name="amount"
                value={formData.amount}
                placeholder="Enter chargeback amount"
                onChange={(e) => {
                  const formattedAmount = backwardAmountInput(cleanInput(e.target.value)) as string;
                  setFormData({ ...formData, amount: formattedAmount });
                }}
                defaultValue={data.amount}
              />
            </div>
          )}
        </div>
        <div className="chargeback-resolve_notice">
          <p>Before resolving this chargeback, make sure to do the necessary checks as this action cannot be reversed.</p>
        </div>
      </div>
    );
  };

  const switchWithdrawModal = (kind: ModalStepT) => {
    let content;
    switch (kind) {
      case 'feedback':
        content = {
          heading: 'Feedback History',
          content: feedbackHistoryContent(),
          firstButtonText: 'Close',
          size: 'md',
          showButtons: false
        };
        break;
      case 'resolve-chargeback':
        content = {
          heading: 'Manage Dispute',
          description:
            'Look through the merchant defense and update the status to reflect the outcome by marking the chargeback as won or lost.',
          content: chargebackResolveContent(),
          secondButtonAction: () => {
            return setModalState('confirm-chargeback');
          },
          size: 'md',
          secondButtonText: 'Resolve',
          secondButtonActionIsTerminal: false,
          secondButtonDisable: chargebackState === '' || (chargebackState === 'partial' && formData.amount === '')
        };
        break;
      case 'confirm-chargeback':
        content = {
          heading: 'Resolve Chargeback',
          content: <p>Are you sure you want to resolve this chargeback?</p>,
          secondButtonText: 'Yes, Proceed',
          firstButtonText: 'Go Back',
          secondButtonAction: () => {
            let newData;
            if (chargebackState === 'won') {
              newData = {
                status: chargebackState
              };
            } else if (chargebackState === 'lost') {
              newData = {
                status: chargebackState
              };
            } else {
              newData = {
                status: chargebackState,
                approved_amount: +formData.amount
              };
            }
            return resolveChargebackMutation.mutateAsync(newData);
          },
          completedHeading: 'Chargeback resolved',
          completedDescription: 'The chargeback has been resolved and the merchant balance updated.',
          secondButtonDisable: false,
          size: 'sm',
          equalFooterBtn: true,
          headerBottomBorder: false
        };
        break;
      default:
        return null;
    }

    return {
      close: () => {
        close();
      },
      ...content
    };
  };
  return <Modal {...(switchWithdrawModal(type) as IModalProps)} />;
};

export default ChargebackDetailsModal;

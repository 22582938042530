import { useQuery } from 'react-query';
import useQueryStorage from './useQueryStorage';
import APIRequest from '+services/api-services';
import queryKeys from '+store/constants/queryKeys';
import useStore from '+store/zustand';
import { BulkActionType } from '+/types';

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);

interface Storage extends WindowSessionStorage {
  checkAuthentication: () => boolean;
}

export default function useGetBulkActions(storage: Storage) {
    const bulkInfo = useStore((state: any) => state.bulkInfo as {bulkInfo: BulkActionType});
    const { refetchQueryData } = useQueryStorage();
    return useQuery( 
      queryKeys.utilities,
      () => apiRequest.getBulkAction(),
      {
        onSuccess: (data) => {
          if (!data) {
            refetchQueryData(queryKeys.utilities);
          }
          useStore.setState({
            bulkInfo: {
              ...bulkInfo,
              ...data.data
            }
          });
        },
      enabled: !!storage.checkAuthentication()
    });
  }
  
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
import React, { useLayoutEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import { useSearchQuery } from '+hooks';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import APIRequest from '+services/api-services';
import EmptyState from '+shared/EmptyState';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal from '+shared/Modal';
import SecondaryDetails from '+shared/SecondaryDetails';
import { ExportActionType, IUserModalDataType } from '+types';
import { APIDownload, capitalize, capitalizeFirst, getDate, getTime, history, isAllowed, logError, switchStatus } from '+utils';

import UserModal from '../components/UserModal';
import UserActivity from './components/UserActivity';
import UserPermissions from './components/UserPermissions';

import BusinessAvatar from '+assets/img/dashboard/user-avatar.svg';

import '../index.scss';

const api = new APIRequest();

type TabType = 'Permissions' | 'Activity';

export default function UserDetails() {
  const queryClient = useQueryClient();
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();

  const from = (location?.state as { from: 'users' | 'invited_users' })?.from || 'users';

  const [modal, setModal] = useState<IUserModalDataType>({
    modalType: null,
    modalInfo: null
  });

  const [tabState, setTabState] = useState<{ isActive: boolean; tabs: TabType[] }>({
    isActive: true,
    tabs: []
  });
  const activeTab = (searchQuery?.value?.tab ?? tabState.tabs[0]) as TabType;
  const [buttonState, setButtonState] = useState(false);

  useLayoutEffect(() => {
    if (isAllowed(userAccess, ['admin_user_audit_logs.view']) && !tabState.tabs.includes('Activity')) {
      setTabState(prev => ({ ...prev, tabs: ['Activity', ...prev.tabs] }));
    }
    if (
      isAllowed(userAccess, [
        'admin_users.update',
        'system_roles.view',
        'my_custom_roles.view',
        'custom_roles.create',
        'admin_user_permissions.view'
      ]) &&
      !tabState.tabs.includes('Permissions')
    ) {
      setTabState(prev => ({ ...prev, tabs: [...prev.tabs, 'Permissions'] }));
    }
  }, [userAccess]);

  useLayoutEffect(() => {
    if (tabState.tabs.length === 0) return;
    searchQuery.setQuery({ tab: tabState.tabs[0] }, false, true);
  }, [tabState.tabs?.length]);

  const isUser = (currentActiveTab: 'users' | 'invited_users') => currentActiveTab === 'users';

  const { data: detailsData, isLoading: isFetchingDetails } = useQuery(
    `${id}_USER_DETAILS_${from.toUpperCase()}`,
    () => (isUser(from) ? api.getUser(id) : api.getInvitedUser(id)),
    {
      onError: () => {
        feedbackInit({
          message: "There has been an error getting this user's information",
          type: 'danger'
        });
      },
      enabled: isAllowed(userAccess, ['admin_user_details.view']) as boolean
    }
  );

  const { data: usersPermissionsData, isLoading: isFetchingUsersPermissions } = useQuery(
    `${id}_USER_PERMISSIONS_${from.toUpperCase()}`,
    () => api.getUsersPermissions(id),
    {
      onError: () => {
        feedbackInit({
          message: "There has been an error getting this user's permissions",
          type: 'danger'
        });
      },
      enabled: (isAllowed(userAccess, ['admin_user_details.view']) && !!id && isUser(from)) as boolean
    }
  );

  const revokeInvitationMutation = useMutation(() => api.cancelAdminUserInvite(detailsData?.code), {
    onSuccess: () => {
      queryClient.invalidateQueries(`${id}_USER_DETAILS_${from?.toUpperCase()}`);
      queryClient.invalidateQueries([`USERS_${from.toUpperCase()}`, 1, 10, {}]);
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error revoking this user's invite`,
        type: 'danger',
        componentLevel: true
      });
    }
  });

  const exportFile = async (
    format: ExportActionType['format'],
    close: ExportActionType['close'],
    fieldsToExport: ExportActionType['fieldsToExport']
  ) => {
    try {
      const newFormat = format === 'csv' ? 'csv' : 'excel';
      const res = await api.exportUsersPermissions(id, newFormat, { entity: fieldsToExport });

      const type = format === 'csv' ? 'csv' : 'xlsx';
      APIDownload(res, `Permissions for user with id:${id}`, type);
      feedbackInit({
        title: 'Export Successful',
        message: (
          <>
            {' '}
            - Successfully exported <strong>user`s permissions.</strong>
          </>
        ),
        type: 'success'
      });

      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your permissions`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const switchTabs = () => {
    const name = isUser(from) ? `${formatUserName(detailsData?.first_name)}  ${formatUserName(detailsData?.last_name, true)}` : null;
    switch (activeTab) {
      case 'Activity':
        return <UserActivity userId={isUser(from) ? detailsData?.id : null} userName={name} />;
      case 'Permissions':
        return (
          !isFetchingDetails &&
          !isFetchingUsersPermissions && (
            <UserPermissions
              userDetails={detailsData}
              readOnly={!isUser(from)}
              from={from}
              usersPermissions={usersPermissionsData}
              exportAction={exportFile}
            />
          )
        );
      default:
        return <UserActivity userId={isUser(from) ? detailsData?.id : null} userName={name} />;
    }
  };

  const formatUserName = (name: string, isLastName = false) => {
    if (!name && !isLastName) return '[Not set]';
    if (!name && isLastName) return '';
    return name;
  };

  const userName = isUser(from)
    ? `${formatUserName(detailsData?.first_name)}  ${formatUserName(detailsData?.last_name, true)}`
    : `[Not set]`;
  const userRole = capitalize(isUser(from) ? `${detailsData?.adminRoles[0]?.name || 'N/A'}` : `${detailsData?.['user_role.name']}`);

  const setRevokeModalVisible = () => {
    setModal({
      modalType: 'revoke',
      modalInfo: {
        heading: 'Revoke invitation?',
        description: (
          <p>
            You are about to revoke <strong>{`${detailsData?.email}’s`}</strong> invitation, they will be unable to access the internal
            dashboard.
          </p>
        ),
        completedHeading: 'Revoked!',
        completedDescription: 'You have successfully revoked this user’s invitation to the internal dashboard',
        action: () => (isAllowed(userAccess, ['admin_user_invitation.delete']) ? revokeInvitationMutation.mutateAsync() : {}),
        secondButtonText: 'Revoke Invitation',
        completedModalSize: 'base',
        equalFooterBtn: true
      }
    });
  };

  const setSuspensionReasonModalVisible = () => {
    setModal({
      modalType: 'reason',
      modalInfo: {
        heading: 'Reason for Suspension',
        description: (
          <>
            <h6 className="mt-md-5">This user was suspended for the following reason:</h6>
            <p>{detailsData?.adminInvitation?.status_description}</p>
            <p className="mt-md-5">
              A suspended user would be unable to sign into their account on this dashboard. Only administrators with the permissions can
              restore a suspended account.
            </p>
          </>
        ),
        showButtons: false
      }
    });
  };

  const getUserDetails = () => {
    const userDetails = [
      {
        'Full Name': userName,
        Email: `${detailsData?.email}`,
        'User ID': `${detailsData?.id}`,
        Status: (
          <>
            <span
              className={`status-pill smaller align-baseline mr-2 ${switchStatus(
                isUser(from) ? detailsData?.adminInvitation?.user_status : detailsData?.status
              )}`}
            />
            {capitalizeFirst(isUser(from) ? detailsData?.adminInvitation?.user_status : detailsData?.status)}
          </>
        )
      },
      {
        Role: userRole,
        'Invited on': (
          <>
            {getDate(isUser(from) ? detailsData?.adminInvitation?.created_at : detailsData?.createdAt)}
            <span className="smaller lighter" style={{ marginLeft: '5px' }}>
              {getTime(isUser(from) ? detailsData?.adminInvitation?.created_at : detailsData?.createdAt)}
            </span>
          </>
        ),
        'Last Active':
          isUser(from) && detailsData.last_login ? (
            <>
              {getDate(detailsData.last_login)}
              <span className="smaller lighter" style={{ marginLeft: '5px' }}>
                {getTime(detailsData.last_login)}
              </span>
            </>
          ) : (
            'N/A'
          )
      }
    ];
    return userDetails;
  };

  const setDefaultAvatar = (error: React.SyntheticEvent<HTMLImageElement, Event>) => {
    error.currentTarget.src = BusinessAvatar;
  };

  return (
    <div className="content-box">
      <div className="row">
        <div className="col-sm-12">
          <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back</span>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="content-details-box">
                <div className="content-details-head --stl-details-head">
                  <div className="content-details-title">
                    <div className="user-avatar-w">
                      <div className="user-avatar">
                        <img
                          alt=""
                          src={isUser(from) ? detailsData?.avatar ?? BusinessAvatar : BusinessAvatar}
                          onError={setDefaultAvatar}
                        />
                      </div>
                    </div>
                    <div className="cd-title ml-3">
                      <h4 className="cd-maintitle" style={{ textTransform: 'uppercase' }}>
                        {isFetchingDetails ? 'Loading...' : userName !== '[Not set]' ? userName : '...'}
                      </h4>
                      <div className="cd-subtitle">{isFetchingDetails ? 'Loading...' : capitalize(userRole ?? '')}</div>
                    </div>
                  </div>
                  <div className="cd-action">
                    <button
                      style={{ color: 'red' }}
                      type="button"
                      className="btn btn-secondary"
                      hidden={
                        isUser(from) ||
                        (!isUser(from) && detailsData?.status !== 'pending') ||
                        !isAllowed(userAccess, ['admin_user_invitation.delete'])
                      }
                      onClick={() => setRevokeModalVisible()}
                    >
                      <i className="os-icon os-icon-minus-circle" />
                      <span>Revoke Invitation</span>
                    </button>
                    {isUser(from) && isAllowed(userAccess, ['admin_users.update']) && (
                      <UserModal setModal={setModal} details={detailsData} from={from} setButtonState={setButtonState} />
                    )}
                  </div>
                </div>
                {isUser(from) && detailsData?.adminInvitation?.user_status === 'suspended' && (
                  <section className="user-banner">
                    This user has been suspended!{' '}
                    <span
                      className="user-banner reason"
                      onClick={setSuspensionReasonModalVisible}
                      onKeyDown={setSuspensionReasonModalVisible}
                      role="button"
                      tabIndex={0}
                    >
                      Learn why
                    </span>
                  </section>
                )}
                {isFetchingDetails ? (
                  <LoadingPlaceholder type="text" content={4} />
                ) : (
                  <div className="os-rolesummary-tabs mb-0">
                    <div className="os-rolesummary os-rolesummary-box">
                      {Object.keys(detailsData || {}).length > 0 ? (
                        <SecondaryDetails title="Account Summary" data={getUserDetails()} />
                      ) : (
                        <EmptyState message="There is an issue getting this user's information" />
                      )}
                    </div>
                  </div>
                )}
                <div className="content-details-body mt-4 --stl-details-body">
                  <div className="row">
                    <div className="col-sm-12 mt-3 mb-5">
                      <div className="os-tabs-w">
                        <div className="os-tabs-controls os-tabs-complex">
                          <ul className="nav nav-tabs mx-5" style={{ paddingLeft: 0 }}>
                            {tabState.tabs.map(tab => {
                              return (
                                <li className="nav-item" key={tab}>
                                  <button
                                    type="button"
                                    onClick={() => searchQuery.setQuery({ tab }, false, true)}
                                    className={`nav-link ${activeTab === tab && 'active'}`}
                                    style={{ fontWeight: 600 }}
                                  >
                                    {tab}
                                  </button>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="mx-3">{switchTabs()}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modal.modalType && (
        <Modal
          secondButtonColor={(modal.modalType !== 'reactivate' && 'red') as string}
          close={() => setModal({ modalType: null, modalInfo: {} })}
          heading={modal.modalInfo?.heading as string}
          description={modal.modalInfo?.description}
          content={modal.modalInfo?.content}
          size="md"
          secondButtonText={modal.modalInfo?.secondButtonText as string}
          secondButtonAction={() => modal.modalInfo?.action?.()}
          secondButtonDisable={modal.modalType !== 'reactivate' ? buttonState : false}
          completedHeading={modal.modalInfo?.completedHeading}
          completedDescription={modal.modalInfo?.completedDescription}
          completedModalSize={modal.modalInfo?.completedModalSize}
          equalFooterBtn={modal.modalInfo?.equalFooterBtn}
          showButtons={modal.modalInfo?.showButtons}
          secondaryCompletedModal
        />
      )}
    </div>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Copyable from '+containers/Dashboard/Shared/Copyable';
import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import SecondaryDetails from '+shared/SecondaryDetails';
import { Params } from '+types';
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, switchStatus } from '+utils';

import { PaymentDataType } from '../IssuedMerchantDetails/data';
import ReceiptModal from './components/ReceiptModal';

import './index.scss';

const api = new APIRequest();

const BillingDetails = () => {
  const { id } = useParams<Params>();
  const { feedbackInit } = useFeedbackHandler();
  const [viewReceipt, setViewReceipt] = useState(false);
  const [activePayment, setActivePayment] = useState({} as PaymentDataType);

  const { data: billingData, isLoading: isFetchingDetails } = useQuery(['BILLING_DETAIL', id], () => api.fetchBillingDetails(id), {
    onError: () => {
      feedbackInit({
        message: 'There has been an error getting the billing detail',
        type: 'danger'
      });
    },
    enabled: !!id
  });

  const {
    currency,
    created_at,
    amount_paid,
    card_category,
    plan_name,
    status,
    cycle_start_date,
    cycle_end_date,
    reference,
    last_charge_date,
    merchant_name,
    amount,
    pending_amount,
    type,
    payment_value,
    transaction_volume,
    payments
  } = billingData || {};

  const summaryDetails = [
    {
      'Invoice Reference': (
        <span>
          <Copyable text={reference} copyText="" spanClassName="reference-text" buttonClassName="" textToCopy={reference} />
        </span>
      ),

      'Invoice Amount': (
        <span>
          <strong>
            {formatAmount(amount || 0)} {currency}
          </strong>
        </span>
      ),
      'Amount Paid': (
        <span>
          <strong>
            {formatAmount(amount_paid || 0)} {currency}
          </strong>
        </span>
      ),
      'Pending Amount': (
        <span>
          <strong>
            {formatAmount(pending_amount || 0)} {currency}
          </strong>
        </span>
      ),
      'Invoice Created At': (
        <span>
          <strong>{getDate(created_at)}</strong> {getTime(created_at)}
        </span>
      ),
      'Last Charged At': (
        <span>
          <strong>{getDate(last_charge_date)}</strong> {getTime(last_charge_date)}
        </span>
      ),
      'Billing Cycle Start date ': (
        <span>
          <strong>{getDate(cycle_start_date)}</strong> {getTime(cycle_start_date)}
        </span>
      ),
      'Billing Cycle End date': (
        <span>
          <strong>{getDate(cycle_end_date)}</strong> {getTime(cycle_end_date)}
        </span>
      )
    },
    {
      Status: (
        <>
          <span className={`status-pill smaller align-baseline ${switchStatus(status)}`} />
          <span className={status === 'complete' ? 'complete-class' : ''}>{capitalizeRemovedash(status) || 'N/A'}</span>
        </>
      ),
      'Subscription Plan': plan_name || 'N/A',
      'Billing Type': <>{capitalizeRemovedash(type)}</>,
      'Card Category': card_category || 'N/A',
      'Total Payment Value': payment_value || 'N/A',
      'Total Transaction Volume': transaction_volume || 'N/A'
    }
  ];

  useEffect(() => {
    if (payments) {
      setActivePayment(payments[0]);
    }
  }, [payments]);

  return (
    <div className="content-i">
      <div className="row">
        <div className="col-sm-12">
          {!isFetchingDetails && (
            <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
              <i className="os-icon os-icon-arrow-left7" />
              <span>
                <strong>Go Back</strong>
              </span>
            </button>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="element-wrapper">
            <div className="element-box">
              <div className="content-details-box">
                <div className="content-details-head --stl-details-head">
                  <div className="content-details-title">
                    <div className="user-avatar-w">
                      <div className="user-avatar">
                        <Icon name="avatar" />
                      </div>
                    </div>
                    <div className="cd-title ml-3">
                      <h3 className="cd-maintitle" style={{ textTransform: 'uppercase' }}>
                        {reference || '- -'}
                      </h3>
                      <div className="cd-subtitle">
                        {capitalize(merchant_name || '- -')}
                        <div className="grey-dot" />
                        {capitalizeRemovedash(type)}
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={() => {
                      setViewReceipt(true);
                    }}
                  >
                    <strong>View Receipt</strong>
                  </button>
                </div>
                {isFetchingDetails ? (
                  <LoadingPlaceholder type="text" content={4} />
                ) : (
                  <SecondaryDetails title="Invoice Details" data={summaryDetails} hideLine leftSpacing="string" />
                )}

                <div className="payments-attempts-comp">
                  <h3>Payments Attempts</h3>
                  <p>{payments?.length || 0} Attempt(s) recorded</p>
                  <div className="payments-attempts-comp__table">
                    <div className="payments-attempts-comp__table-left">
                      {payments?.map((payment: any) => (
                        <button
                          type="button"
                          className={`${activePayment === payment ? 'active' : ''}`}
                          onClick={() => setActivePayment(payment)}
                        >
                          <span>
                            Charge attempt at{' '}
                            <strong>
                              {getDate(payment.created_at)}, {getTime(payment.created_at)}
                            </strong>
                          </span>
                          <span className={`status-pill smaller align-baseline ${switchStatus(payment.status)}`} />
                        </button>
                      ))}
                    </div>
                    <div className="payments-attempts-comp__table-right">
                      <div className="payments-attempts-comp__table-row">
                        <p>Status</p>
                        <span>
                          <strong style={{ color: '#24B314' }}>{capitalizeRemovedash(activePayment.status)}</strong>
                        </span>
                      </div>
                      <div className="payments-attempts-comp__table-row">
                        <p>Amount</p>
                        <span>
                          <strong>
                            {activePayment.amount}
                            {currency}
                          </strong>
                        </span>
                      </div>
                      <div className="payments-attempts-comp__table-row">
                        <p>Date Created</p>
                        <span>
                          {getDate(activePayment?.created_at)} | {getTime(activePayment?.created_at)}
                        </span>
                      </div>
                      <div className="payments-attempts-comp__table-row">
                        <p>Debit Type</p>
                        <span>{capitalizeRemovedash(activePayment.debit_type)}</span>
                      </div>
                      <div className="payments-attempts-comp__table-row">
                        <p>Reference</p>
                        <span style={{ color: '#2376F3', fontWeight: '500' }}>
                          <Copyable
                            text={activePayment.reference}
                            copyText=""
                            spanClassName="reference-text"
                            buttonClassName=""
                            textToCopy={activePayment.reference}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {viewReceipt && <ReceiptModal isOpen={viewReceipt} onClose={() => setViewReceipt(false)} receiptData={billingData} />}
    </div>
  );
};

export default BillingDetails;

import React, { ElementRef, useRef } from 'react';

import Modal from './Modal';

import KpyLoader from '+assets/img/dashboard/kpy-loader.svg';

export default function FileViewer({
  showView,
  setShowView,
  url,
  size = 'lg'
}: {
  showView: boolean;
  setShowView: (e: boolean) => void;
  url: string;
  size?: 'sm' | 'lg' | 'md';
}) {
  const loaderRef = useRef<ElementRef<'image'>>(null);
  return showView ? (
    <Modal
      close={() => setShowView(false)}
      size={size}
      heading="File Viewer"
      content={
        <section className="iframe-container">
          <img src={KpyLoader} alt="loader" ref={loaderRef} width="40px" />
          <img
            className="image-viewer"
            alt="file-viewer"
            src={url}
            // title="file-viewer"
            // sandbox=""
            onLoad={() => {
              loaderRef.current!.style.display = 'none';
            }}
          />
        </section>
      }
      showButtons={false}
      description=""
      secondButtonText=""
    />
  ) : null;
}

/**
 * @description Singleton class for logging messages,with the option to enable or disable logging based on the environment.
 * 
 * Private constructor
 * 
 * Get the singleton instance of Logger.
 * @returns The Logger instance.
 * 
 * logMessage
 * @param  args The arguments to log.
 * 
* @exampleimport { Logger } from './Logger';
* create an instance
* const logger = Logger.getInstance();
* 
* use it any way in your components or functions
* 
* logger.logMessage('Hello');
*/
class Logger {
    private static instance: Logger | null = null;
    private log: (...args: any[]) => void;
  
    private constructor() {
      if (process.env.NODE_ENV === 'development') {
        this.log = console.log.bind(console);
      } else {
        this.log = () => {};
      }
    }
  
    static getInstance(): Logger {
      if (!Logger.instance) {
        Logger.instance = new Logger();
      }
      return Logger.instance;
    }
  
    logMessage(...args: any[]): void {
      this.log(...args);
    }
  }
  
  export { Logger };

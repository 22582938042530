import React from 'react';

import { regions } from '+utils';

import AccessRequestCountry from './AccessRequestCountry';

import './index.scss';

export default function AccessRequestCountries({ title, data }: { title: string; data?: Record<string, string[]> }) {
  const countries = data ? Object.entries(data).map(([country, useCase]) => ({ country, useCase })) : [];
  if (!countries.length) {
    return null;
  }
  return (
    <div className="arc">
      <h2 className="arc__title">{title}</h2>
      <div className="arc__countries">
        <div className="arc__countries-header">
          <div className="arc__countries-header__title">Country</div>
          <div className="arc__countries-header__title">Use Case of Interest</div>
        </div>
        <div className="arc__countries-list">
          {countries.map((country) => (
            <AccessRequestCountry key={country.country} useCase={country.useCase} country={country.country as keyof typeof regions} />
          ))}
        </div>
      </div>
    </div>
  );
}

import React, { useState } from 'react';

import { useGenerateReport, useSetUserAccess } from '+hooks';
import { isAllowed } from '+utils';

import GenerateReportModal from './components/GenerateReportModal';
import GenerateReportsTable from './components/GenerateReportTable';

import './index.scss';

const Reports = () => {
  const userAccess = useSetUserAccess();
  const [genrateReportModalVisible, setGenrateReportModalVisible] = useState<boolean>(false);
  const {
    isLoading: generateReportLoading,
    generateReportMutation,
    isSuccess: generateReportSuccessResponse,
    isError: isGenerateReporError
  } = useGenerateReport();
  const showGenerateReportModal = () => {
    setGenrateReportModalVisible(true);
  };
  const closeGenerateReportModal = () => {
    setGenrateReportModalVisible(false);
  };

  return (
    <div className="content-i">
      <div className="content-box results-wrapper">
        <div className="top-container">
          <div className="top-layer-1">
            <h5 className="form-header" data-testid="generate-report-header">
              Generate Report
            </h5>
            <div className="sub-title" data-testid="generate-report-subtitle">
              <p>
                Generate transaction report from third-party processors by selecting the date range for the transactions processed by the
                processor. Generated reports can be downloaded and will be forwarded to your email.
              </p>
            </div>
          </div>
          {isAllowed(userAccess, ['third_party_reports.create']) && (
            <div className="top-layer-2">
              <button
                className="btn btn-primary"
                type="button"
                style={{ height: 'max-content' }}
                onClick={showGenerateReportModal}
                data-testid="generate-report-button"
              >
                <i className="os-icon os-icon-plus" />
                <span>Generate Report</span>
              </button>
            </div>
          )}
        </div>
        <div className="table-layer-container">
          <GenerateReportsTable actionFn={showGenerateReportModal} />
        </div>
        <GenerateReportModal
          visible={genrateReportModalVisible}
          closeModal={closeGenerateReportModal}
          generateReportLoading={generateReportLoading}
          generateReportMutation={generateReportMutation}
          generateReportSuccessResponse={generateReportSuccessResponse}
          isGenerateReporError={isGenerateReporError}
        />
      </div>
    </div>
  );
};
export default Reports;

import { Dispatch, SetStateAction } from 'react';
import { capitalizeRemovedash } from '+utils';
import { useFormik } from 'formik';

export type TLimitsTierObj = {
  [key in 'tier_one' | 'tier_two']: Record<'single' | 'daily' | 'monthly', number>;
};

export type TAccountLimits = {
  [key in 'individual' | 'corporate']?: TLimitsTierObj;
};

export type TLimitsPayload = {
  type: 'individual' | 'corporate';
  tier: 'tier_one' | 'tier_two';
  limits: Record<'single' | 'daily' | 'monthly', number>;
};

export type TLimitsApiError = { response: { data: { message: string } } };

export type TModalType = 'edit' | 'confirm' | 'completed' | null;
export type TAccountType = 'individual' | 'corporate';
export type TLimits = Record<'daily' | 'monthly' | 'single', number>;

export type TEditLimitModal = {
  setModalType: Dispatch<SetStateAction<TModalType>>;
  formSubmit: (newLimits: TLimits) => void;
  newLimit: TLimitsPayload;
  newLimitCap: TLimits;
};

export type TConfirmEditModal = {
  setModalType: Dispatch<SetStateAction<TModalType>>;
  updateLimits: () => void;
};

export type TEditCompletedModal = {
  setModalType: Dispatch<SetStateAction<TModalType>>;
};

export const tiers = (tier: string) =>
  ({
    tier_one: 1,
    tier_two: 2
  }[tier]);

export const limitsForm = (
  limit: TLimitsPayload,
  newLImitCap: TLimits,
  formSubmit: (arg: TLimits) => void,
  type: 'single' | 'daily' | 'monthly'
) =>
  useFormik({
    initialValues: limit.limits,
    validateOnChange: true,
    validate: (values: TLimits) => {
      const errors = {} as Record<keyof TLimits, string>;
      const isTier1 = limit.tier === 'tier_one';

      (Object.keys(values) as [keyof TLimits]).forEach((key) => {
        const limitType = key === 'single' ? 'per transaction' : key;
        if (values[key] < 1) errors[key] = `${capitalizeRemovedash(key)} limit cannot be zero, Please enter positive values`;
        if (values[key] && !/^[0-9]+$/.test(String(values[key]))) errors[key] = `Invalid ${capitalizeRemovedash(limitType)} limit amount`;
        if (values[key] > 9_000_000_000_000) errors[key] = `${capitalizeRemovedash(limitType)} limit is too high`;
        if (isTier1 && values[key] > newLImitCap[key]) errors[key] = `Tier 1 ${limitType} limit cannot exceed tier 2 ${key} limit`;
        if (!isTier1 && values[key] < newLImitCap[key]) errors[key] = `Tier 2 ${limitType} limit should exceed tier 1 ${limitType} limit`;
      });

      if (values.single > +values.daily) {
        errors.single = 'The limit per transaction cannot exceed the daily limit. Please adjust the limits';
      }

      if (values.daily < +values.single) errors.daily = 'Daily Limit should exceed the per transaction limit. Pease adjust the limits';

      if (values.daily > +values.monthly) errors.daily = 'Daily limit cannot exceed the monthly limit. Please adjust the limits';

      if (values.monthly < +values.daily) {
        errors.monthly = 'Monthly limit should exceed the daily limit. Please adjust the limits';
      }

      return errors;
    },
    onSubmit: (values) => {
      formSubmit(values);
    }
  });

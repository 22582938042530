import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const usePageHeader = () => {
  const location = useLocation();
  const [header, setHeader] = useState<string | null>('');

  useEffect(() => {
    const heading = getPageHeader();
    setHeader(heading);
  }, [location.pathname]);

  // Base page headers
  const pageHeaders: Record<string, string> = {
    '/dashboard/home': 'Home',
    '/dashboard/analytics': 'Dashboard',
    '/dashboard/pay-ins': 'Pay-ins',
    '/dashboard/payouts': 'Payouts',
    '/dashboard/disputes': 'Disputes',
    '/dashboard/reversals': 'Reversals',
    '/dashboard/virtual-accounts': 'Virtual Accounts',
    '/dashboard/settlement-payouts': 'Settlement Payouts',
    '/dashboard/settlements': 'Settlements',
    '/dashboard/audit-logs': 'Audit Logs',
    '/dashboard/webhooks': 'Webhooks',
    '/dashboard/settings': 'Settings',
    '/dashboard/merchants': 'Merchants',
    '/dashboard/card-issuance': 'Card Issuance',
    '/dashboard/payment-reversals': 'Reversals',
    '/dashboard/disputes/chargeback/add': 'Disputes',
    '/dashboard/settings/fees': 'Default Fees',
    '/dashboard/utilities': 'Utilities',
    '/dashboard/user': 'Users',
    '/dashboard/currency-exchange': 'Currency Exchange',
    '/dashboard/reports': 'Reports',
    '/dashboard/partners-balance': 'Partner Balance',
    '/dashboard/paused/payouts': 'Paused Payments',
    '/dashboard/bulk-payouts': 'Bulk Payouts',
    '/dashboard/product-config': 'Product Configuration',
    '/dashboard/settlements/merchant': 'Merchant Settlements',
    '/dashboard/disputes/chargeback/summary': 'Upload Summary',
    '/dashboard/identity': 'Identity'
  };

  // Sub page headers
  const partialPageHeaders = [
    { path: '/dashboard/payouts/', header: 'Transaction Details' },
    { path: '/dashboard/pay-ins/', header: 'Transaction Details' },
    { path: '/dashboard/currency-exchange/', header: 'Transaction Details' },
    { path: '/dashboard/payment-reversals/', header: 'Transaction Details' },
    { path: '/dashboard/settlements/', header: 'Settlement Details' },
    { path: '/dashboard/disputes/', header: 'Dispute Details' },
    { path: '/dashboard/virtual-accounts/', header: 'Virtual Account Details' },
    { path: '/dashboard/merchant/', header: 'Merchant Details' },
    { path: '/dashboard/kyc-approval/', header: 'Merchant KYC' },
    { path: '/dashboard/partners-balance/transaction/', header: 'Transaction Details' },
    { path: '/dashboard/bulk-payouts/', header: 'Bulk Payout Details' },
    { path: '/dashboard/product-config/', header: 'Product Configuration' },
    { path: '/dashboard/partners-balance/', header: 'Partner Balance' },
    { path: '/dashboard/card-issuance/merchants/', header: 'Issuing Merchant' },
    { path: '/dashboard/card-issuance/cards/', header: 'Virtual Card Details' },
    { path: '/dashboard/card-issuance/billing/', header: 'Card Issuance' },
    { path: '/dashboard/card-issuance/chargebacks/', header: 'Chargeback Details' },
    { path: '/dashboard/identity/verifications/', header: 'Verification Event Details' }
  ];

  const getPageHeader = () => {
    if (pageHeaders[location.pathname]) {
      return pageHeaders[location.pathname];
    }

    const partialHeader = partialPageHeaders.find(({ path }) => location.pathname.includes(path));

    return partialHeader ? partialHeader.header : null;
  };

  return header;
};

export default usePageHeader;

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, ReactElement, SetStateAction } from 'react';
import { Link } from 'react-router-dom';

import {
  Bank,
  GetTransactionUtilitiesData,
  GetTransactionUtilitiesState,
  PaymentChannelsType,
  TConversionsTrxn,
  TRelatedTransactions,
  TTransactionDetails,
  useTransactionStatusStateType
} from '+types';
import {
  capitalize,
  capitalizeFirst,
  capitalizeRemovedash,
  cardStatus,
  formatAmount,
  formatPAN,
  getDate,
  getTime,
  history,
  payinMethodMapping,
  payoutMethodMapping,
  specialisedDate,
  switchBank,
  switchCard,
  switchChannel,
  switchCurrency,
  switchStatus,
  truncateString
} from '+utils';

import Copyable from '../Shared/Copyable';
import RefundsTab from './components/ReversalTabs/RefundsTab';
import ReversalsTab from './components/ReversalTabs/ReversalsTab';
import { chargebackDetails } from './details/chargebacks';
import { payInDetails } from './details/payins';
import { payOutDetails } from './details/payouts';
import { refundDetails } from './details/refunds';
import { virtualCardDetails } from './details/virtual-card';

import mobileIconPNG from '+assets/img/dashboard/mobile.png';
import cardIcon from '+assets/img/dashboard/card-icon.svg';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import mobileIconSVG from '+assets/img/dashboard/mobile.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';

export const getBankImageByName = (name: Bank): string => {
  return switchBank[name as keyof typeof switchBank] || switchBank.default;
};

export const getTransactionUtilities = (data: GetTransactionUtilitiesData, state: GetTransactionUtilitiesState) => {
  const {
    transactionType,
    payment,
    destination,
    source,
    meta,
    status,
    message,
    payment_source,
    payment_source_type: paymentSourceType,
    amount_collected: amountCollected,
    amount_charged: amountCharged,
    currency,
    amount,
    merchant_email,
    reversal_reason,
    card,
    card_acceptor_country,
    card_acceptor_name,
    refetchTransaction,
    channel,
    processor,
    processor_reference: processorReference,
    merchant_bears_cost: merchantBearsCost,
    trace_id: traceId,
    transaction_date: createdAt,
    completed_at: completedAt,
    payment_reversals_type: refundOrChargeback,
    type: cardTransactionType = '',
    description,
    cross_currency,
    amount_paid: netAmount,
    fee,
    vat,
    narration,
    transaction_date: transactionDate,
    reference,
    account,
    created_at
  } = data;
  const { reversalsControls, setModalOpen, setReversalsControls } = state;

  return {
    paymentChannels: (item: PaymentChannelsType): React.ReactElement => (
      <>
        {item === 'wallet' && (
          <>
            <span>
              <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
              <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
            </span>
            <span>
              {transactionType === 'pay-ins' ? payment?.customer?.email || 'Not provided' : destination?.details?.email || 'Not provided'}
            </span>
          </>
        )}
        {item === 'card' &&
          (source?.details?.masked_pan ? (
            <>
              <span>
                <img
                  src={switchCard[source?.details?.card_type as keyof typeof switchCard]}
                  className="card-logo"
                  alt="card-logo"
                  style={{ width: '1.5rem', marginRight: '8px' }}
                />
                <span style={{ display: 'inline' }}>{capitalize(source?.details?.card_type)}</span>
              </span>
              <span>
                {meta?.card_details?.first_six
                  ? formatPAN(`${meta?.card_details?.first_six}${source.details.masked_pan?.slice(6)}`)
                  : formatPAN(source.details.masked_pan)}
              </span>
            </>
          ) : (
            <span>
              <img src={cardIcon} className="card-logo" alt="card-icon" />
              <span style={{ display: 'inline' }}>No card information available</span>
            </span>
          ))}
        {item === 'mobile_money' && (
          <>
            <span>
              <img
                id="phone-icon"
                src={mobileIconSVG}
                alt="phone icon"
                srcSet={mobileIconPNG}
                style={{ marginLeft: destination?.details?.mobile_name ? 0 : '-1.5rem', height: '23px', width: '13px' }}
              />
              <span style={{ display: 'inline', marginLeft: '.5rem', color: '#636C72' }}>
                {destination?.details?.mobile_name || 'Operator Information Unavailable'}
              </span>
            </span>
            {destination?.details?.mobile_number && <span>{`+${destination?.details?.mobile_number}` || 'Not Available'}</span>}
          </>
        )}
        {item === 'bank_account' && (
          <>
            <span>
              <img
                id="bank-icon"
                src={getBankImageByName(destination?.details?.bank_name)}
                alt="bank"
                style={{ marginLeft: destination?.details?.bank_name ? 0 : '-1.5rem' }}
              />
              <span style={{ display: 'inline', marginLeft: '0.2rem', color: '#a9afbc' }}>
                {destination?.details?.bank_name || 'No Bank Information Available'}
              </span>
            </span>
            {destination?.details?.bank_name && <span>{destination?.details?.account_number || 'Not Available'}</span>}
          </>
        )}
      </>
    ),

    PayInDetails: {
      status: (
        <>
          <span
            className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(status === 'requires_auth' ? 'Pending (Requires Authorization)' : status || 'Not Available')}
        </>
      ),
      ...(['expired', 'failed'].includes(status) && {
        [`reason_for_${status === 'expired' ? 'expiry' : 'failure'}`]: message
      }),
      amount_paid: `${
        transactionType === 'refunds'
          ? formatAmount(payment_source?.amount || payment_source?.amount_collected)
          : paymentSourceType === 'bank_transfer'
          ? formatAmount(amountCollected)
          : formatAmount(amountCharged || amount)
      } ${currency}`,
      ...(paymentSourceType === 'bank_transfer' && { amount_expected: `${formatAmount(amountCharged)} ${currency}` }),
      payment_method: payinMethodMapping[source?.type as keyof typeof payinMethodMapping] || 'Not Available',
      ...(['bank_transfer', 'pay_with_bank'].includes(source?.type) && {
        bank: (
          <>
            <img
              id="bank-icon"
              src={getBankImageByName(source?.details?.bank_slug)}
              alt="bank icon"
              style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
            />
            <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
              {capitalize(source?.details?.bank_name || 'No Bank Information Available')}
            </span>
          </>
        )
      }),
      ...(source?.type === 'wallet' && { wallet: null }),
      ...(source?.type === 'card' && { card: null }),
      ...(['bank_transfer', 'pay_with_bank', 'wallet'].includes(source?.type) && {
        account_number: source?.details?.account_number || 'Not Available'
      }),
      account_name: (
        <>
          {source?.type === 'bank_transfer' && <span>{capitalize(source?.details?.account_name || 'Not Available')}</span>}
          {['card', 'wallet', 'pay_with_bank', 'mobile_money'].includes(source?.type) && (
            <span>{capitalize(payment?.customer?.name || 'Not Available')}</span>
          )}
        </>
      ),
      ...(['mobile_money'].includes(source?.type) && {
        network_provider: source?.details?.network_provider || 'Operator Information Unavailable'
      }),
      ...(['mobile_money'].includes(source?.type) && {
        mobile_number: source?.details?.mobile_number
      }),
      email: (['chargebacks'].includes(transactionType) ? merchant_email : payment?.customer?.email) || 'Not provided',
      ...(status === 'success' && transactionType === 'refunds' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {})
    },

    PayoutDetails: {
      status: (
        <>
          <span
            className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(status === 'requires_auth' ? 'Pending (Requires Authorization)' : status || 'Not Available')}
        </>
      ),
      ...(['expired', 'failed'].includes(status) ? { [`reason_for_${status === 'expired' ? 'expiry' : 'failure'}`]: message } : {}),
      amount_paid: `${formatAmount(amountCharged)} ${currency}`,
      payment_method: payoutMethodMapping[destination?.type as keyof typeof payoutMethodMapping] || 'Not Available',
      ...(destination?.type === 'bank_account' && { bank_account: null }),
      ...(destination?.type === 'wallet' && { wallet: null }),
      ...(destination?.type === 'mobile_money' && { mobile_money: null }),
      recipient: capitalize(destination?.details?.account_name || payment?.customer?.name || 'Not Available'),
      email: payment?.customer?.email || 'Not provided',
      ...(status === 'success' && transactionType === 'refunds' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {})
    },

    cardTransactionDetails: {
      "cardholder's_name": `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      PAN: (
        <>
          <img
            src={switchCard[card?.brand as keyof typeof switchCard]}
            className="card-logo"
            alt="card-logo"
            style={{ width: '1.5rem', marginRight: '8px' }}
          />
          <Copyable text={card?.last_four} spanClassName="semibold" textModifier={text => `**** **** **** ${text}`} />
        </>
      ),
      Card_ID: <Copyable text={card?.reference} textModifier={text => truncateString(text, 15)} />,
      expiry_date: specialisedDate(card?.expiry, 'MM/YY')
    },

    virtualCardsBusinessInformation: {
      business_name: card_acceptor_name || 'Not Available',
      business_country: card_acceptor_country || 'Not Available',
      business_email: 'Not Available'
    },

    virtualAccount: (): React.ReactNode => {
      return (
        <>
          <p className="section-heading">Customer&apos;s Virtual Account</p>
          <p>
            <span>
              <img
                id="bank-icon"
                src={getBankImageByName(source?.details?.bank_slug)}
                alt="bank icon"
                style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
              />
              <span style={{ display: 'inline', marginLeft: 0, color: '#636C72' }}>
                {capitalizeRemovedash(source?.virtual_bank_account?.bank_name || 'No bank information Available')}
              </span>
            </span>
            {source?.virtual_bank_account?.bank_name && <span>{source?.virtual_bank_account?.account_number || 'Not Available'}</span>}
          </p>
          <p>
            <span>Account Name</span>
            <span>{capitalize(source?.virtual_bank_account?.account_name || 'Not Available')}</span>
          </p>
          {source?.virtual_bank_account?.expiry && (
            <p>
              <span>Expired At</span>
              <span>{`${getDate(source.virtual_bank_account.expiry)}, ${getTime(source.virtual_bank_account.expiry)}`}</span>
            </p>
          )}
        </>
      );
    },

    ReversalTabs: (): React.ReactNode => {
      return (
        <li>
          <p className="section-heading refund-heading" id="section-title">
            Refunds, Reversals & Chargebacks
            <button type="button" onClick={() => setModalOpen(true)}>
              Learn more
              <img src={InfoIcon} alt="info" />
            </button>
          </p>

          <div className="rversal-rfund-cback">
            <div className="reversals-tabs-list" id="reversals-tabs-list">
              <div className="os-tabs-controls os-tabs-complex" id="reversals-tabs-list">
                <ul className="nav nav-tabs">
                  {['Refunds', 'Reversals', 'Chargebacks'].map(tab => (
                    <li className="nav-item" key={tab}>
                      <button
                        id="tab"
                        type="button"
                        aria-expanded="false"
                        className={tab === reversalsControls.activeTab ? 'nav-link active' : 'nav-link'}
                        data-toggle="tab"
                        onClick={() => setReversalsControls({ activeTab: tab })}
                      >
                        <span className="tab-label">{tab}</span>
                        <span style={{ marginLeft: '3px', fontWeight: '400' }}>
                          {reversalsControls.data?.[reversalsControls.activeTab!]?.length}
                        </span>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <span className="render-tabs">
              {reversalsControls.activeTab === 'Refunds' && <RefundsTab data={reversalsControls.data?.refunds} currency={currency} />}
              {reversalsControls.activeTab === 'Reversals' && (
                <ReversalsTab data={reversalsControls.data?.reversals} currency={currency} refetch={refetchTransaction} />
              )}
              {reversalsControls.activeTab === 'Chargebacks' && (
                <RefundsTab data={reversalsControls.data?.chargebacks} currency={currency} />
              )}
            </span>
          </div>
        </li>
      );
    },

    moreDetailsOptions: {
      shared: {
        currency_charged: switchCurrency[currency as keyof typeof switchCurrency],
        'Korapay ID': <Copyable text={payment?.reference} textModifier={text => truncateString(text, 8)} /> || 'Not available',
        channel: switchChannel(channel)
      },
      common: {
        transaction_type: (
          <span>
            {transactionType === 'pay-ins'
              ? channel === 'web' && source?.type !== 'wallet'
                ? 'Deposit'
                : 'Pay-in'
              : channel === 'web'
              ? 'Withdrawal'
              : 'Payout'}
          </span>
        ),
        processor: `${processor || 'Not available'}`,
        'Processor ID': `${processorReference || 'Not available'}`,
        fee_bearer: `${merchantBearsCost ? 'Merchant' : 'Customer'}`,
        ...(!['refunds'].includes(transactionType) && {
          channel: switchChannel(channel)
        }),
        ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
        ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
        ...(traceId ? { trace_id: traceId } : {}),
        ...(meta?.stan && { stan: <Copyable text={meta?.stan} /> }),
        approval_code: meta?.authorization_code ? <Copyable text={meta?.authorization_code} /> : 'Not Available',
        date_created: `${getDate(createdAt)}, ${getTime(createdAt)}`,
        date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available',
        payment_reversals_type: refundOrChargeback ? 'Yes' : 'None'
      },
      'virtual-card': {
        transaction_type: <span className="semibold">{capitalizeRemovedash(cardTransactionType)}</span>,
        ...(cardTransactionType !== 'card_creation' && { description }),
        'cross-currency': cross_currency ? 'Yes' : 'No'
      },
      refunds: {
        ...(completedAt ? { date_completed: `${getDate(completedAt)}, ${getTime(completedAt)}` } : {}),
        processor: `${capitalizeRemovedash(payment_source?.processor || 'Not available')}`,
        processor_reference: `${payment_source?.processor_reference || 'Not available'}`,
        channel: `${switchChannel(payment_source?.channel) || 'Not available'}`,
        settlement_reference: `${payment_source?.settlement?.reference || 'Not available'}`
      }
    },
    txBreakdownOptions: {
      common: {
        net: `${formatAmount(netAmount)} ${currency}`,
        fee: `${formatAmount(parseFloat(fee || '0') + parseFloat(vat || '0'))} ${currency}`,
        date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available',
        merchant: <span>{payment?.account?.name || 'Not Available'}</span>,
        description: `${channel === 'web' ? 'Balance Top Up' : narration || 'Not Available'}`
      },
      'virtual-card': {
        amount: `${formatAmount(amount)} ${currency}`,
        'date_/_time': `${getDate(transactionDate)} ${getTime(transactionDate)}`,
        cardholder: `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
        'Transaction ID': (
          <>
            <Copyable text={reference?.toUpperCase?.()} textModifier={text => truncateString(text, 18)} />
            <br />
            <button
              type="button"
              className="btn btn-original"
              onClick={() => history.push(`/dashboard/card-issuance/cards/${card?.reference}`, null)}
            >
              <span>See Virtual Card</span>
              <i className="os-icon os-icon-arrow-up-right" />
            </button>
          </>
        )
      },
      refunds: {
        refund_amount: `${formatAmount(amount) || 'Not available'}`,
        date_created: created_at ? `${getDate(created_at)}, ${getTime(created_at)}` : 'Not Available',
        merchant: <span>{capitalizeFirst(account?.name || 'Not Available')}</span>,
        transaction_id: (
          <>
            <Copyable text={payment?.reference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
            <button
              type="button"
              className="btn btn-original"
              onClick={() => history.push(`/dashboard/pay-ins/${payment_source?.reference}`, null)}
            >
              <span>See original transaction</span>
              <i className="os-icon os-icon-arrow-up-right" />
            </button>
          </>
        )
      }
    }
  };
};

export const virtualAccount = (source: any) => {
  return (
    <>
      <p className="section-heading">Customer&apos;s Virtual Account</p>
      <p>
        <span>
          <img
            id="bank-icon"
            src={getBankImageByName(source?.details?.bank_slug)}
            alt="bank icon"
            style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
          />
          <span style={{ display: 'inline', marginLeft: 0, color: '#636C72' }}>
            {capitalizeRemovedash(source?.virtual_bank_account?.bank_name || 'No bank information Available')}
          </span>
        </span>
        {source?.virtual_bank_account?.bank_name && <span>{source?.virtual_bank_account?.account_number || 'Not Available'}</span>}
      </p>
      <p>
        <span>Account Name</span>
        <span>{capitalize(source?.virtual_bank_account?.account_name || 'Not Available')}</span>
      </p>
      {source?.virtual_bank_account?.expiry && (
        <p>
          <span>Expired At</span>
          <span>{`${getDate(source.virtual_bank_account.expiry)}, ${getTime(source.virtual_bank_account.expiry)}`}</span>
        </p>
      )}
    </>
  );
};

export const additionalInfoOpt =
  ({
    data,
    transactionType,
    updateTransactionStatusModalState,
    state,
    userAccess
  }: {
    data: TTransactionDetails;
    transactionType: string;
    updateTransactionStatusModalState: (a: boolean, b: any) => void;
    state: useTransactionStatusStateType;
    userAccess: { [key: string]: boolean };
  }) =>
  (type: string) => {
    switch (type) {
      case 'payouts':
        return {
          title: 'Recipient Information',
          data: payOutDetails({ data, transactionType, updateTransactionStatusModalState, state, userAccess }).breakdown
        };
      case 'pay-ins':
        return {
          title: "Payer's Information",
          data: payInDetails({ data, transactionType, updateTransactionStatusModalState, state, userAccess }).breakdown
        };
      case 'virtual-card':
        return {
          title: "Cardholder's Information",
          data: virtualCardDetails({ data, transactionType, updateTransactionStatusModalState, state, userAccess }).breakdown
        };
      case 'refunds':
        return {
          title: 'Refund Details',
          data: refundDetails({ data }).breakdown
        };
      case 'chargebacks':
        return {
          title: 'Chargeback Details',
          data: chargebackDetails({ data }).breakdown
        };
      case 'chargebacks-payins':
        return {
          title: "Payer's Information",
          data: payInDetails({ data, transactionType, updateTransactionStatusModalState, state, userAccess }).breakdown
        };
      default:
        return null;
    }
  };

export const paymentChannel =
  ({
    transactionType,
    payment,
    destination,
    source,
    meta
  }: {
    transactionType: any;
    payment: any;
    destination: any;
    source: any;
    meta: any;
  }) =>
  (item: string) => {
    const mobileMoneyProvider = transactionType === 'payouts' ? destination?.details?.mobile_name : source?.details?.network_provider;
    const mobileMoneyNumber = transactionType === 'payouts' ? destination?.details?.mobile_number : source?.details?.mobile_number;

    return (
      <>
        {item === 'wallet' && (
          <>
            <span>
              <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
              <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
            </span>
            <span>
              {transactionType === 'pay-ins' ? payment?.customer?.email || 'Not provided' : destination?.details?.email || 'Not provided'}
            </span>
          </>
        )}
        {item === 'card' &&
          (source?.details?.masked_pan ? (
            <>
              <span>
                <img
                  src={switchCard[source?.details?.card_type as keyof typeof switchCard]}
                  className="card-logo"
                  alt="card-logo"
                  style={{ width: '1.5rem', marginRight: '8px' }}
                />
                <span style={{ display: 'inline' }}>{capitalize(source?.details?.card_type)}</span>
              </span>
              <span>
                {meta?.card_details?.first_six
                  ? formatPAN(`${meta?.card_details?.first_six}${source.details.masked_pan?.slice(6)}`)
                  : formatPAN(source.details.masked_pan)}
              </span>
            </>
          ) : (
            <span>
              <img src={cardIcon} className="card-logo" alt="card-icon" />
              <span style={{ display: 'inline' }}>No card information available</span>
            </span>
          ))}
        {item === 'mobile_money' && (
          <>
            <span>
              <img
                id="phone-icon"
                src={mobileIconSVG}
                alt="phone icon"
                srcSet={mobileIconPNG}
                style={{ marginLeft: mobileMoneyProvider ? 0 : '-1.5rem', height: '23px', width: '13px' }}
              />
              <span style={{ display: 'inline', marginLeft: '.5rem', color: '#636C72' }}>
                {mobileMoneyProvider || 'Operator Information Unavailable'}
              </span>
            </span>
            {mobileMoneyNumber && <span>{`${mobileMoneyNumber}` || 'Not Available'}</span>}
          </>
        )}
        {item === 'bank_account' && (
          <>
            <span>
              <img
                id="bank-icon"
                src={getBankImageByName(destination?.details?.bank_name)}
                alt="bank"
                style={{ marginLeft: destination?.details?.bank_name ? 0 : '-1.5rem' }}
              />
              <span style={{ display: 'inline', marginLeft: '0.2rem', color: '#a9afbc' }}>
                {destination?.details?.bank_name || 'No Bank Information Available'}
              </span>
            </span>
            {destination?.details?.bank_name && <span>{destination?.details?.account_number || 'Not Available'}</span>}
          </>
        )}
      </>
    );
  };

const RelatedTransactionsWrapper = ({
  multiple,
  link,
  children,
  headerText
}: {
  multiple: boolean;
  link: string;
  children: ReactElement;
  headerText?: string;
}) => {
  return (
    <div className="related-transactions">
      <p className="section-heading more-trxn-heading">
        {headerText || `Related Transaction${multiple ? 's' : ''}`}
        <Link to={link}>
          See {multiple && 'All'} Related Transaction{multiple && 's'} <i className="os-icon os-icon-arrow-up-right" />
        </Link>
      </p>
      <ul className="related-transactions__list">{children}</ul>
    </div>
  );
};

export const relatedTransactions = ({ uniqueRef, trxns }: { uniqueRef: string; trxns: TRelatedTransactions[] }): React.ReactElement => {
  const extraCardStatus = (status: string) => cardStatus[status as keyof typeof cardStatus];

  return (
    <RelatedTransactionsWrapper
      multiple={trxns.length > 1}
      link={`/dashboard/pay-ins?dateFrom=all_time&dateTo=all_time&page=1&sorterType=filter&uniqueReference=${uniqueRef}`}
    >
      <>
        {trxns.map(trx => (
          <li key={trx.reference} className="related-transactions__list-item">
            <div>
              {getDate(trx.transaction_date)} {getTime(trx.transaction_date)}
            </div>
            <div>
              <span>{uniqueRef}</span>
              <span>{trx.reference}</span>
            </div>
            <div>
              <span>
                <i className={`status-pill smaller ${switchStatus(trx.status)}`} />{' '}
                {extraCardStatus(trx.status) || capitalizeRemovedash(trx.status)}
              </span>
              <span>
                {trx.meta?.charge_operation && (
                  <span>{trx.meta.charge_operation === 'pre_authorization' ? 'Auth' : trx.meta.charge_operation.split('_')[0]}</span>
                )}
              </span>
            </div>
            <div>
              {formatAmount(trx.amount)} {trx.currency}
            </div>
          </li>
        ))}
      </>
    </RelatedTransactionsWrapper>
  );
};

export const conversionsRelatedTrxns = ({ trxns }: { trxns: TConversionsTrxn }): React.ReactElement => {
  return (
    <RelatedTransactionsWrapper
      headerText="Related Transaction / Conversion"
      multiple={false}
      link={`/dashboard/pay-ins/${trxns.source?.reference}`}
    >
      <li className="related-transactions__list-item--conversions">
        <div>
          <i className={`status-pill smaller ${switchStatus(trxns.status)}`} /> {capitalizeRemovedash(trxns.status)}
        </div>
        <div>
          <span>{trxns.source?.reference}</span>
          <span>{trxns.reference}</span>
        </div>

        <div>
          {formatAmount(trxns.source_amount)} {trxns.source_currency}
        </div>

        <div>
          {getDate(trxns.transaction_date)} {getTime(trxns.transaction_date)}
        </div>
      </li>
    </RelatedTransactionsWrapper>
  );
};

export const getHighlightedAmount = ({
  amountCollected,
  amount,
  sourceAmount
}: { [key in 'amountCollected' | 'amount' | 'sourceAmount']: string }) => {
  const n = (num: string) => Number(num);
  return (n(amountCollected) && amountCollected) || (n(amount) && amount) || (n(sourceAmount) && sourceAmount) || '0.00';
};

export const allActionButtonList = ({
  hasPayoutReversal,
  setShowReversalInitiateModal,
  setManageRefundsModalOpen,
  setChargebackModalState,
  setManagePausedPaymentsModal,
  transactionType
}: {
  hasPayoutReversal: boolean;
  setShowReversalInitiateModal: Dispatch<SetStateAction<boolean>>;
  setManageRefundsModalOpen: Dispatch<SetStateAction<boolean>>;
  setChargebackModalState: Dispatch<SetStateAction<{ type: string; open: boolean }>>;
  setManagePausedPaymentsModal: Dispatch<SetStateAction<string>>;
  transactionType: string;
}) => {
  return {
    payoutReversal: {
      label: hasPayoutReversal ? 'Reversal initiated' : 'Reverse Payout',
      onClick: () => setShowReversalInitiateModal(true),
      disabled: hasPayoutReversal,
      iconRight: <i className="os-icon os-icon-corner-up-left" />,
      color: 'light-blue'
    },
    manageRefunds: {
      label: 'Manage Refund',
      onClick: () => setManageRefundsModalOpen(true),
      iconLeft: <i className="os-icon os-icon-settings" />
    },
    chargebacks: {
      label: 'View History',
      onClick: () => setChargebackModalState({ type: 'feedback', open: true }),
      disabled: transactionType !== 'chargebacks',
      color: 'primary'
    },
    managePausedPayments: {
      buttons: [
        {
          label: 'Cancel',
          color: 'light-blue',
          onClick: () => setManagePausedPaymentsModal('cancel_single')
        },
        {
          label: 'Process',
          color: 'primary',
          onClick: () => setManagePausedPaymentsModal('process_single')
        }
      ]
    }
  };
};

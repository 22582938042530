/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useQueryStorage from '+hooks/useQueryStorage';
import APIRequest from '+services/api-services';
import CurrencyPicker from '+shared/CurrencyPicker';
import SettlementsConfig from '+shared/SettlementsConfig';
import { customTabOrder, logError, storageDataKey } from '+utils';

import Conversions from './Conversions';

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);

export default function MerchantSettlements() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams();
  const [activeCurrency, setActiveCurrency] = useState('NGN');

  const { storeQueryData, getQueryData } = useQueryStorage();
  const data = getQueryData(storageDataKey.SINGLE_MERCHANT);
  const { settlements } = data || { settlements: {} };

  const getMerchantSettlementsMutation = useMutation(({ variable }) => apiRequest.getMerchantSettlements(variable), {
    onError: error => {
      logError(error);
      feedbackInit({
        message: 'There has been an error fetching merchant settlements, Please try again',
        type: 'danger'
      });
    }
  });

  const getMerchantSettlements = variable => {
    return getMerchantSettlementsMutation.mutateAsync({ variable });
  };

  const getConfig = useCallback(async () => {
    try {
      const merchantSettlements = await getMerchantSettlements(id);
      const merchant = { ...data, settlements: merchantSettlements.data };
      storeQueryData(storageDataKey.SINGLE_MERCHANT, merchant);
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Merchant Settlement',
        message: 'There&apos;s been an error getting merchants settlements configuration',
        type: 'danger'
      });
    }
  }, []);

  useEffect(() => {
    if (settlements && !Object.keys(settlements).includes(activeCurrency)) {
      setActiveCurrency(Object.keys(settlements)[0]);
    }
  }, [settlements, activeCurrency]);

  return (
    <div className="nav-content active">
      <div className="element-box">
        <div className="element-box-heading" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className="heading-box-mmd">
            <h4 className="form-header">Settlement Configuration</h4>
            <div className="form-desc no-underline mb-2">You can adjust the configuration of this merchant’s settlements here.</div>
          </div>
        </div>

        <CurrencyPicker
          options={Object.keys(customTabOrder(settlements) || {})}
          onChange={value => {
            setActiveCurrency(value);
          }}
          className="mt-2 mb-3"
          activeCurrency={activeCurrency}
          label={<strong>Showing configuration for:</strong>}
          id="merchant-settlements-settings__currency-switch"
        />

        <Conversions activeCurrency={activeCurrency} id={id} />

        {settlements?.[activeCurrency] && (
          <SettlementsConfig
            data={settlements}
            activeCurrency={activeCurrency}
            setActiveCurrency={setActiveCurrency}
            source="merchant"
            getConfig={getConfig}
            merchantID={id}
            merchantRiskLevel={data?.risk_level || ''}
          />
        )}
      </div>
    </div>
  );
}

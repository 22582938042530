import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';

import useExport from '+hooks/useExport';
import { CurrencyType, FileFormatType } from '+types';
import { capitalizeRemovedash, durationToday, durationWeek, isObjectAllTrueOrAllFalse } from '+utils';

import CustomCheckbox from './CustomCheckbox';
import Modal from './Modal';

import calendar from '+assets/img/dashboard/calendar.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

const MIN_DATE = new Date('01-01-2016');
const MAX_DATE = new Date();
function getOptions(
  type: string,
  status?: string,
  activeCurrency?: CurrencyType,
  options?: Record<string, string>
): Record<string, string> | null {
  switch (type) {
    case 'pay-in':
      return {
        status: 'Status',
        payment_type: 'Payment Method',
        amount_paid: 'Amount Paid',
        amount_charged: 'Amount Charged',
        settlement_reference: 'Settlement ID',
        payment_reference: 'Payment ID',
        fee: 'Fee',
        reference: 'Reference',
        processor: 'Processor',
        processor_reference: 'Processor Reference',
        merchant: 'Merchant',
        vat: 'VAT',
        customer_name: 'Customer',
        transaction_date: 'Date Created',
        customer_email: 'Customer Mail',
        completed_at: 'Date Completed',
        currency: 'Currency',
        narration: 'Narration',
        trace_id: 'Session/Trace ID',
        masked_pan: 'PAN',
        ...(['GHS', 'KES'].includes(activeCurrency!) ? { mobile_number: 'Mobile number', mobile_network: 'Mobile network' } : {}),
        ...(activeCurrency === 'NGN' && { stamp_duty: 'Stamp Duty' })
      };
    case 'pay-outs':
      return {
        status: 'Status',
        reference: 'Transaction Reference',
        merchant: 'Merchant',
        customer_email: 'Customer Mail',
        currency: 'Currency',
        amount_paid: 'Amount Paid',
        amount_charged: 'Amount Charged',
        fee: 'Fee',
        vat: 'VAT',
        trace_id: 'Session/Trace ID',
        transaction_date: 'Date Created',
        processor: 'Processor',
        processor_reference: 'Processor Reference',
        customer_name: 'Customer',
        completed_at: 'Date Completed',
        narration: 'Narration',
        ...(['GHS', 'KES'].includes(activeCurrency!) ? { mobile_number: 'Mobile number', mobile_network: 'Mobile network' } : {})
      };
    case 'settlements':
      return {
        status: 'Status',
        amount: 'Settled Amount',
        settlement_reference: 'Settlement ID',
        total_transaction_amount: 'Amount Collected',
        created_at: 'Date Created',
        merchant_name: 'Merchant',
        currency: 'Currency',
        type: 'Category',
        processor: 'Processor',
        total_transaction_vat: 'Settlement Vat',
        destination_type: 'Destination',
        settlement_payout_vat: 'Settlement Payout Vat',
        settlement_date: 'Date Due',
        transaction_volume: 'Volume',
        settlement_payout_fee: 'Settlement Payout Fee',
        total_transaction_fee: 'Settlement Fee',
        trace_id: 'Session/Trace ID',
        bank_name: 'Bank Name'
      };
    case 'settlements-details':
      return {
        status: 'Status',
        amount: 'Settled Amount',
        processor_reference: 'Proccessor ID',
        created_at: 'Date Created',
        customer_name: 'Merchant',
        currency: 'Currency',
        type: 'Category',
        processor: 'Processor',
        settle_at: 'Date Due',
        fee: 'Settlement Fee',
        paymentReference: 'Payment Refrence',
        payment_source_type: 'Source',
        reference: 'Refrence',
        channel: 'Channel',
        response_code: 'Code',
        message: 'Message',
        narration: 'Narration',
        vat: 'VAT',
        amount_charged: 'Amount charged',
        amount_paid: 'Amount Paid'
      };
    case 'settlements-payouts':
      return {
        status: 'Status',
        processor: 'Processor',
        fee: 'Fee',
        vat: 'VAT',
        created_at: 'Date Created',
        merchant: 'Merchant',
        currency: 'Currency',
        trace_id: 'Session/Trace ID',
        settlement_amount: 'Settlement Amount',
        settle_to: 'Settle To',
        category: 'Category',
        processor_reference: 'Processor Reference',
        total_transaction_fees: 'Total Transaction Fees',
        total_transaction_vat: 'Total Transaction Vat',
        settlement_mode: 'Settlement Mode',
        reference: 'Reference',
        settlement_reference: 'Settlement Reference',
        amount: 'Amount',
        settled_at: 'Settlement Date',
        total_transaction_amount: 'Total Transaction Amount',
        payment_sources_count: 'Payment Sources Count'
      };
    case 'merchant':
      return {
        merchant_name: 'Merchant',
        merchant_id: 'Merchant ID',
        date_created: 'Date Created',
        status: 'Status',
        contact: 'Contact',
        mode: 'Mode',
        merchant_country: 'Merchant Country',
        merchant_email: 'Email',
        sra: 'SRA'
      };
    case 'payment-preferences':
      return {
        merchant_name: 'Merchant',
        request_status: 'Status',
        merchant_id: 'Merchant ID',
        contact: 'Contact',
        date_created: 'Date Created'
      };
    case 'reversals':
      return {
        currency: 'Currency',
        status: 'Status',
        merchant: 'Merchant',
        amount: 'Amount',
        reference: 'Reversal Reference',
        reversal_date: 'Reversal Date',
        transaction_reference: 'Pay-in Reference',
        trace_id: 'Trace ID',
        processor: 'Processor',
        processor_reference: 'Processor Reference',
        customer_name: 'Customer Name',
        completed_at: 'Completed At',
        message: 'Message',
        type: 'Reversal Type'
      };
    case 'payout_reversals':
      return {
        status: 'Status',
        merchant: 'Merchant',
        amount_paid: 'Amount Paid',
        amount_charged: 'Amount Charged',
        fee: 'Fee',
        vat: 'VAT',
        currency: 'Currency',
        transaction_date: 'Transaction Date',
        reversal_date: 'Reversal Date',
        completed_at: 'Completed At',
        reason: 'Reversal Reason',
        reference: 'Reversal Reference',
        payout_reference: 'Payout Reference',
        customer_name: 'Customer Name',
        customer_email: 'Customer Email',
        channel: 'Channel',
        processor: 'Processor',
        processor_reference: 'Processor Reference',
        trace_id: 'Trace ID',
        narration: 'Narration'
      };
    case 'balances':
      return {
        amount: 'Amount',
        description: 'Description',
        direction: 'Direction',
        source_type: 'Type',
        reference: 'Reference ID',
        transaction_reference: 'Source Reference',
        balance_before: 'Balance Before',
        balance_after: 'Balance After',
        created_at: 'Date/Time',
        currency: 'Currency',
        history_date: 'Release Date'
      };
    case 'refunds':
      return {
        reference: 'Refund Reference',
        refund_amount: 'Refund Amount',
        amount_collected: 'Amount Collected',
        status: 'Status',
        transaction_reference: 'Transaction Reference',
        type: 'Type',
        payment_reference: 'Payment Reference',
        customer_name: 'Customer Name',
        customer_email: 'Customer Email',
        channel: 'Channel',
        currency: 'Currency',
        merchant: 'Merchant',
        processor: 'Processor',
        processor_reference: 'Processor Reference',
        reversal_date: 'Reversal Date',
        completed_at: 'Date Completed'
      };
    case 'webhooks':
      return {
        status: 'Status',
        webhook_date: 'Triggered At',
        response_code: 'Response Code',
        transaction_reference: 'Transaction ID',
        attempts: 'Attempts',
        merchantName: 'Merchant',
        last_attempt: 'Last Attempt',
        created_at: 'Created At',
        id: 'Webhook ID',
        webhook_url: 'Webhook URL',
        type: 'Type'
      };
    case 'settlement-detail':
      return {
        transaction_reference: 'Transaction ID',
        date_created: 'Date Created',
        amount: 'Amount',
        fees: 'Fees',
        [status?.toLowerCase() || '']: status === 'Settled' ? 'Amount Settled' : 'Amount to Settle'
      };
    case 'issued-cards':
      return {
        reference: 'Reference ID',
        currency: 'Currency',
        date_created: 'Date Created',
        status: 'Status',
        type: 'Card Type',
        brand: 'Card Brand',
        holder_name: 'Cardholder Name',
        first_six: 'First Six Digits',
        last_four: 'Last Four Digits',
        expiry: 'Card Expiry Date'
      };
    case 'card-events':
      return {
        reference: 'Reference',
        event: 'Event',
        reason: 'Reason',
        creator: 'Creator',
        date: 'Date'
      };
    case 'card-balance-history':
      return {
        transaction_id: 'Transaction ID',
        date_created: 'Date Created',
        time_created: 'Time Created',
        type: 'Transaction Type',
        direction: 'Transaction Direction',
        amount_settled: 'Settled Amount',
        amount_collected: 'Amount Collected',
        balance_before: 'Balance Before',
        balance_after: 'Balance After'
      };
    case 'balance-history':
    case 'card-details-transactions':
    case 'cards-transaction':
      return {
        status: 'Status',
        amount: 'Amount',
        fee: 'Fee',
        currency: 'Currency',
        cross_currency: 'Cross Currency',
        card_holder_name: "Cardholder's Name",
        type: 'Type',
        description: 'Description',
        date: 'Date',
        reference: 'Transaction ID',
        balance_after: 'Balance After'
      };
    case 'issuance-chargebacks':
      return {
        status: 'Status',
        reference: 'Chargeback ID',
        amount: 'Chargeback Amount',
        currency: 'Currency',
        escalation_date: 'Date Escalated',
        accepted_pre_arbitration_amount: 'Accepted Pre-Arb Amount',
        re_escalated_amount: 'Reescalated Amount',
        re_escalation_date: 'Reescalation Date',
        actual_resolution_date: 'Resolution Date',
        processing_date: 'Processing Date',
        refund_status: 'Refund Status',
        transaction_reference: 'Transaction ID',
        transaction_amount: 'Transaction Amount',
        transaction_type: 'Transaction Type',
        transaction_date: 'Transaction Date',
        card_type: 'Card Type',
        card_holder_name: 'Cardholder Name',
        first_six: 'First Six Digits',
        last_four: 'Last Four Digits',
        card_expiry_date: 'Card Expiry Date',
        card_brand: 'Card Brand'
      };
    case 'issuing-merchants':
      return {
        name: 'Merchant Name',
        status: 'Issuing Status',
        reference: 'Merchant ID',
        plan: 'Subscription Plan',
        date_created: 'Date added',
        wallet_balance: 'Wallet Balance'
      };
    case 'chargebacks':
      return {
        reference: 'Chargeback Reference',
        amount: 'Amount Collected',
        approved_amount: 'Approved Amount',
        batch_code: 'Batch Code',
        log_code: 'Log Code',
        status: 'Status',
        payment_reference: 'Payment Reference',
        merchant_email: 'Merchant Email',
        reason: 'Reason',
        currency: 'Currency',
        merchant: 'Merchant',
        processor: 'Processor',
        processor_reference: 'Processor Reference',
        payment_source_reference: 'Payment Source Reference',
        payment_reversal_reference: 'Payment Reversal Reference',
        created_at: 'Date Created',
        deadline: 'Deadline',
        account_id: 'Account ID'
      };
    case 'wallet_swap':
      return {
        status: 'Status',
        reference: 'Transaction ID',
        merchant_name: 'Merchant Name',
        source_amount: 'Source Amount',
        source_currency: 'Source Currency',
        converted_amount: 'Converted Amount',
        destination_currency: 'Destination Currency',
        service_charge: 'Service Charge',
        exchange_rate: 'Exchange Rate',
        transaction_date: 'Transaction Date'
      };
    case 'issuing_history':
      return {
        transaction_reference: 'Transaction Reference',
        direction: 'Direction'
      };
    case 'bulk-payouts':
      return {
        status: 'Status',
        batch_reference: 'Transaction ID',
        merchant: 'Merchant',
        currency: 'Currency',
        amount: 'Amount Paid',
        fee: 'Fee',
        completed_at: 'Date Completed'
      };
    case 'bulk-transactions':
      return {
        status: 'Status',
        batch_reference: 'Transaction ID',
        merchant: 'Merchant',
        currency: 'Currency',
        amount: 'Amount Paid',
        reference: 'Transaction Reference',
        customer_email: 'Customer Mail',
        transaction_date: 'Date Created',
        customer_name: 'Customer',
        narration: 'Narration'
      };
    case 'paused-payments':
      return {
        status: 'Product Name',
        reference: 'Transaction Reference',
        merchant: 'Merchant',
        currency: 'Currency',
        amount_paid: 'Amount Paid',
        transaction_date: 'Date Created'
      };
    case 'partners_balance':
      return {
        status: 'Status',
        source_account_number: 'Source Account Number',
        destination_account_number: 'Destination Account Number',
        source_account_name: 'Source Account Name',
        destination_account_name: 'Destination Account Name',
        source_institution_name: 'Source Institution Name',
        destination_institution_name: 'Destination Institution Name',
        reference: 'Reference',
        processor_reference: 'Processor Reference',
        amount: 'Amount',
        currency: 'Currency',
        initiated_by: 'Initiated By',
        initiator_email: 'Initiator Email',
        completed_at: 'Completed At',
        created_at: 'Created At',
        session_id: 'Session ID'
      };
    case 'verification-events':
      return {
        id: 'ID',
        reference: 'Reference',
        first_name: 'First Name',
        middle_name: 'Middle Name',
        last_name: 'Last Name',
        phone_number: 'Phone Number',
        email: 'Email',
        gender: 'Gender',
        identity_type: 'Identity Type',
        requested_by: 'Requested By',
        status: 'Status',
        result: 'Event Result',
        type: 'Category',
        country: 'Country',
        date_created: 'Date Created'
      };
    case 'identity-merchants':
      return {
        reference: 'Reference',
        active: 'Status',
        date_created: 'Date Created',
        kora_id: 'Kora ID',
        name: 'Name',
        email: 'Email'
      };
    case 'identity-requests':
      return {
        name: 'Name',
        email: 'Email',
        status: 'Status',
        date_created: 'Date Created',
      }
    case 'identity-billing':
      return {
        date_created: 'Date Created',
        verification_reference: 'Billing for',
        verification_type: 'Category',
        type: 'Billing Type',
        identity_type: 'ID Type',
        amount: 'Amount',
        status: 'Status'
      };
    case 'permissions':
      return options || {};
    case 'merchant-balance-history':
      return {
        amount: 'Amount',
        description: 'Description',
        source_type: 'Type',
        history_date: 'Date',
        transaction_reference: 'Reference ID',
        balance_before: 'Balance Before',
        created_at: 'Time',
        currency: 'currency',
        balance_after: 'Balance After',
        source_reference: 'Source Reference',
        current_balance: 'Current Balance',
        direction: 'Direction'
      };
    default:
      return null;
  }
}

type ExportModalProps = {
  openExport: boolean;
  close: () => void;
  exportAction: (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => void;
  type: string;
  dateRange?: boolean;
  defaultExport?: boolean;
  heading?: string;
  description?: string;
  fileName?: string;
  status?: string;
  exportMessage?: string;
  largeExportAction?: () => void;
  activeCurrency?: CurrencyType;
  options?: Record<string, string>;
  exportModalHeaderBottomBorder?: boolean;
  exportModalScrollable?: boolean;
};

const AdvanceExportModal = ({
  openExport,
  close,
  exportAction,
  type,
  dateRange = false,
  heading = 'Export Transactions',
  description = 'Choose how you would like to export these transactions',
  status,
  defaultExport = true,
  fileName = '',
  exportMessage = '',
  largeExportAction = () => {},
  activeCurrency,
  options: currentOptions,
  exportModalHeaderBottomBorder = false,
  exportModalScrollable = false
}: ExportModalProps): JSX.Element => {
  const rangeOptions = ['today', 'last_7_days', 'all_time', 'custom_range'];
  const switchDuration = {
    today: durationToday(),
    last_7_days: durationWeek(),
    all_time: [null, null]
  };

  const getDuration = () => {
    const start = startDate ? dayjs(startDate).format('YYYY-MM-DD') : null;
    const end = endDate ? dayjs(endDate).format('YYYY-MM-DD') : null;
    if (details.rangeSelected === 'custom_range') return [start, end];
    return switchDuration[details.rangeSelected as keyof typeof switchDuration];
  };

  const options = useMemo(() => getOptions(type, status, activeCurrency, currentOptions), [type, status, activeCurrency, currentOptions]);
  function markAll() {
    if (!options) return;
    const newObj = {};
    // eslint-disable-next-line array-callback-return
    Object.keys(options || {}).map(i => {
      newObj[i] = true;
    });
    return newObj;
  }

  const [format, setFormat] = useState<FileFormatType>('csv');
  const [disableAll, setDisableAll] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState('all');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [details, setDetails] = useState({
    kind: markAll(),
    rangeSelected: 'today',
    selected: options ? Object.keys(options) : []
  });

  const selectedKinds = () => {
    return Object.keys(details.kind).filter(kind => details.kind[kind]);
  };

  useEffect(() => {
    setDetails({
      kind: markAll(),
      rangeSelected: 'today',
      selected: Object.keys(options || {})
    });
  }, [options]);

  useEffect(() => {
    if (Object.keys(options || {}).length === details.selected.length) return setSelectedColumn('all');
    return setSelectedColumn('custom');
  }, [details]);

  useEffect(() => {
    if (selectedColumn === 'all') {
      setSelectedColumn('all');
      setDisableAll(true);
      setDetails({
        kind: markAll(),
        rangeSelected: 'today',
        selected: Object.keys(options || {})
      });
    } else {
      setDisableAll(false);
    }
  }, [selectedColumn, type]);

  const getExport = useExport(exportAction, format, fileName, exportMessage, close, details.selected, largeExportAction);

  const handleExport = () => {
    if (!defaultExport) return getExport();
    if (type === 'balances' || type === 'merchant-balance-history') return exportAction(format, close, details.selected, ...getDuration());
    return exportAction(format, close, details.selected);
  };
  const checkIFAllCheckBoxIsUnckecked = (obj: { [key: string]: boolean }): boolean => {
    if (obj === null || obj === undefined) return false;
    if (selectedColumn === 'custom') {
      return isObjectAllTrueOrAllFalse(obj, false);
    }
    return false;
  };

  const ExportComponent = () => (
    <div className="filter-body row">
      <div className="form-group col-12">
        <label htmlFor="format">Export As</label>
        <select className="form-control" name="format" onChange={e => setFormat(e.target.value)} value={format}>
          <option value="csv">CSV (file.csv)</option>
          <option
            value={
              [
                'refunds',
                'settlements',
                'chargebacks',
                'settlements-payouts',
                'pay-outs',
                'pay-in',
                'cards-transactions',
                'wallet_swap',
                'settlement',
                'issued-cards',
                'card_events',
                'card-details-transactions',
                'issuing-history',
                'issuing-merchants',
                'issuance-chargebacks',
                'bulk-payouts',
                'bulk-transactions',
                'paused-payments',
                'partners_balance',
                'verification-events',
                'identity-billing',
                'identity-merchants',
                'settlements-details',
                'merchant-balance-history'
              ].includes(type)
                ? 'excel'
                : 'xlsx'
            }
          >
            Excel 2007 and later (file.xlsx)
          </option>
        </select>
      </div>

      {dateRange && (
        <section className="form-group col-12">
          <label htmlFor="format mb-2">Date Range</label>
          <section>
            {rangeOptions.map(option => (
              <div className="form-check mb-1" key={option}>
                <label>
                  <input
                    className="form-check-input"
                    type="radio"
                    checked={details.rangeSelected === option}
                    onChange={e => {
                      if (e.target.checked)
                        setDetails(oldDetails => ({
                          ...oldDetails,
                          rangeSelected: option
                        }));
                    }}
                  />
                  {capitalizeRemovedash(option)}
                </label>
              </div>
            ))}
          </section>
        </section>
      )}

      {details.rangeSelected === 'custom_range' && (
        <div className="form-group col-12" style={{ '--calendar-image': `url(${calendar})`, display: 'flex' }}>
          <DatePicker
            selected={startDate}
            dateFormat="dd-MM-yyyy"
            style={{ borderRadius: '4px 0px 0px 4px', marginRight: '10px' }}
            className="date-select form-control pl-4"
            onChange={date => setStartDate(date)}
            placeholderText="From"
            calendarClassName="custom-datepicker"
            minDate={MIN_DATE}
            maxDate={MAX_DATE}
          />
          <DatePicker
            selected={endDate}
            dateFormat="dd-MM-yyyy"
            style={{ borderRadius: '0px 4px 4px 0px' }}
            className="date-select form-control pl-4"
            minDate={startDate || null}
            onChange={date => setEndDate(date)}
            placeholderText="To"
            calendarClassName="custom-datepicker"
            maxDate={MAX_DATE}
          />
        </div>
      )}

      {options && (
        <>
          <div className="form-group col-12">
            <label htmlFor="column">{`What ${
              ['permissions', 'roles'].includes(type) ? 'entities' : 'column(s)'
            } would you like to have in the exported file?`}</label>
            <select
              className="form-control"
              name="column"
              onChange={e => {
                setSelectedColumn(e.target.value);
              }}
              value={selectedColumn}
            >
              <option value="all">All</option>
              <option value="custom">Custom ({details.selected.length})</option>
            </select>
          </div>
          <div className="d-flex flex-wrap">
            {Object.keys(options || {}).map(option => (
              <div className="col-sm-4" key={option}>
                <div className="form-check mb-3" key={option}>
                  <CustomCheckbox
                    disabled={disableAll}
                    type="checkbox"
                    checked={details.kind[option]}
                    onChange={value => {
                      details.kind[option] = value;
                      setDetails({ ...details, selected: selectedKinds() });
                    }}
                    text={options[`${option}`]}
                  />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <Modal
      close={close}
      size="md"
      heading={heading}
      description={description}
      content={<ExportComponent />}
      visible={openExport}
      firstButtonText="Cancel"
      secondButtonText="Export"
      secondButtonAction={handleExport}
      secondButtonActionIsTerminal={false}
      headerBottomBorder={exportModalHeaderBottomBorder}
      isScrollable={exportModalScrollable}
      secondButtonDisable={checkIFAllCheckBoxIsUnckecked(details && details?.kind ? details?.kind : '')}
    />
  );
};

export default AdvanceExportModal;

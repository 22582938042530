import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Switch } from 'react-router-dom';

import { useFeedbackHandler, useSearchQuery } from '+hooks';
import useLargeExportDownloader from '+hooks/useLargeExportDownloader';
import APIRequest from '+services/api-services';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import { conversionsTableDataType } from '+types';
import { APIDownload, capitalize, filteredOutObjectProperty, formatAmount, getDate, getTime, isAllowed, logError } from '+utils';
import useStore from '+zustandStore';

import TransactionDetails from '../TransactionDetails';
import ManageExchangeRate from './components/ManageExchangeRate';

import arrowRight from '+assets/img/dashboard/arrow-right-black.svg';

import './index.scss';

const api = new APIRequest();

const ExchangeRates = () => {
  const { feedbackInit } = useFeedbackHandler();
  const [manageExchange, setManageExchange] = useState(false);
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const { profile } = useStore(state => state);
  const searchQuery = useSearchQuery();
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;

  const sortingParams = {
    ...filteredOutObjectProperty(searchQuery.value, ['page', 'limit', 'sorterType'])
  };

  const feedbackInitValues = {
    message: '',
    type: 'danger',
    action: {
      action: () => refetch(),
      name: 'Try again'
    },
    title: undefined,
    callback: undefined,
    statusCode: undefined,
    isActive: false,
    isClosable: true,
    componentLevel: true
  };

  const { getDownload } = useLargeExportDownloader('Conversions');

  useEffect(() => {
    getDownload();
  }, []);

  const {
    data: walletData,
    refetch,
    isFetching
  } = useQuery(['SWAP_LIST', searchQuery.value], () => api.getSwaps(page, limit, sortingParams), {
    keepPreviousData: true,
    onError: () => {
      feedbackInit({
        ...feedbackInitValues,
        title: 'Conversions',
        message: 'There has been an error getting Conversions',
        type: 'danger'
      });
    }
  });

  const data = walletData?.data || [];
  const paging = walletData?.paging || {};

  const exportWalletSwaps = async (format: string, close: () => void, fieldsToExport: any) => {
    try {
      const res = await api.exportSwaps(format, fieldsToExport, sortingParams);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Wallet Swap Report for ${getDate(Date.now())}`, type);
        feedbackInit({
          ...feedbackInitValues,
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>{paging?.total_items} transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        ...feedbackInitValues,
        title: 'Export Failed',
        message: `There has been an error downloading your transactions`,
        type: 'danger'
      });
    }
  };

  const Conversions = {
    className: '--swaps-table',
    rowKey: 'reference',
    rowURL: '/dashboard/currency-exchange',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no wallet swap transactions yet.',
    annotations: 'transaction(s)',
    fields: (item: conversionsTableDataType) => ({
      data: {
        'Date / Time': (
          <>
            <span>{getDate(item.transaction_date)}</span>
            <span className="annotation">{getTime(item.transaction_date)}</span>
          </>
        ),
        transaction_id: <span className="trxn-id">{item.reference}</span>,
        merchant: <span className="merch-name">{capitalize(item.account?.name || 'Not Available')}</span>,
        'currency pair': (
          <>
            <span className="currency-pair">
              <span className="annotation">{item.source_currency}</span>
              <img sizes="sm" width={10} height={10} src={arrowRight} alt="arrow right icon" aria-hidden />
              <span className="annotation">{item.destination_currency}</span>
            </span>
          </>
        ),
        'amount converted': (
          <>
            <span>
              <strong>{formatAmount(item.source_amount)}</strong>
            </span>
            <span className="annotation">{item.source_currency}</span>
          </>
        ),
        'converted to': (
          <>
            <span>
              <strong>{formatAmount(item.converted_amount)}</strong>
            </span>
            <span className="annotation">{item.destination_currency}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(Conversions.fields({}).data);

  const getTitle = (): string => {
    const filtered: boolean = Object.keys(sortingParams || {}).length > 0;
    return `${filtered ? 'Filtered' : 'All'} `;
  };

  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
      <div className="content-i">
        <div className="content-box">
          <div className="row">
            <div className="col-sm-12">
              <div className="element-wrapper">
                <h6 className="element-header">{getTitle()}</h6>
                {
                  <div className="element-actions d-sm-block">
                    <div className="search-filter-box">
                      <button data-testid="manage-exchange-rate-button"  className="btn-sm filter-button" type="button" onClick={() => setManageExchange(true)}>
                        <i className="os-icon os-icon-repeat" />
                        <span>Manage Exchange Rate</span>
                      </button>
                    </div>
                  </div>
                }
                {manageExchange && <ManageExchangeRate close={() => setManageExchange(false)} />}
                <Table
                  data={data}
                  loading={isFetching}
                  className={`${Conversions.className || ''}`}
                  tableWrapperClassName="table-responsive"
                  paginationPage
                  renderFields
                  hasPagination
                  tableHeadings={tableDataKeys}
                  annotation={Conversions.annotations}
                  current={parseInt(page, 10)}
                  rowKey={Conversions.rowKey}
                  rowURL={Conversions.rowURL}
                  pageSize={paging?.page_size || 0}
                  totalItems={paging?.total_items || 0}
                  limitAction={(limit: number) => searchQuery.setQuery({ limit: String(limit) })}
                  actionFn={(current: number) => searchQuery.setQuery({ page: String(current) })}
                  emptyStateHeading={Conversions.emptyStateHeading || ''}
                  emptyStateMessage={Conversions.emptyStateMessage || ''}
                  filterType="wallet_swap"
                  filterHasAdvancedFilter={false}
                  filterExportAction={exportWalletSwaps}
                  filterKeywordPlaceholder="Search Transaction"
                  filterQueryIDPlaceholder="Payout ID"
                  filterTotalCount={paging?.total_items}
                  filterShowExport={isAllowed}
                >
                  {Conversions.fields}
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default function CurrencyExchange(data: Object) {
  return (
    <div className="currency-exchange__container">
      <Switch>
        <Route exact path="/dashboard/currency-exchange" component={ExchangeRates} />
        <Route path="/dashboard/currency-exchange/:id">
          <TransactionDetails />
        </Route>
      </Switch>
    </div>
  );
}

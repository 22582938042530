import React, { ChangeEvent, useEffect, useState } from 'react';

import './NotificationTypeCheckboxes.scss';

interface NotificationTypeCheckboxesItem {
  label: string;
  value: string;
}

export interface NotificationTypeCheckboxesProps {
  items: NotificationTypeCheckboxesItem[];
  activeState?: boolean;
  getNotificationTypes: (notificationTypes: string[]) => void;
  notificationTypes?: string[] | string | null | undefined;
}

const NotificationTypeCheckboxes: React.FC<NotificationTypeCheckboxesProps> = ({
  items,
  activeState,
  getNotificationTypes,
  notificationTypes
}) => {
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({});
  const [allChecked, setAllChecked] = useState<boolean>(false);

  useEffect(() => {
    const checkedItemsArr = Object.keys(checkedItems).filter(key => checkedItems[key] === true);
    getNotificationTypes(checkedItemsArr);
  }, [checkedItems]);

  useEffect(() => {
    if (notificationTypes?.length === 0) {
      setAllChecked(true);
      const updatedCheckedItemsObj: Record<string, boolean> = {};
      items.forEach(item => {
        updatedCheckedItemsObj[`${item.value}`] = true;
      });
      setCheckedItems(updatedCheckedItemsObj);
    } else if (notificationTypes.length > 0) {
      const updatedCheckedItemsObj: Record<string, boolean> = {};
      items.forEach(item => {
        updatedCheckedItemsObj[`${item.value}`] = notificationTypes?.includes(item.value);
      });
      setCheckedItems(updatedCheckedItemsObj);
    }
  }, [items]);

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === 'all' && checked) {
      setAllChecked(true);
      const updatedCheckedItemsObj: Record<string, boolean> = {};
      items.forEach(item => {
        updatedCheckedItemsObj[`${item.value}`] = checked;
      });
      setCheckedItems(updatedCheckedItemsObj);
    } else {
      setAllChecked(false);
      setCheckedItems(prevCheckedItems => ({
        ...prevCheckedItems,
        [name]: checked
      }));
    }
  };

  return (
    <div className="notification-type-options">
      {items.map(item => (
        <div key={item.label} className="notification-type-option">
          <div className="notification-checkbox">
            <input
              data-testid="checkbox-notificationTypes"
              type="checkbox"
              name={item.value}
              checked={checkedItems[`${item.value}`] || false}
              onChange={handleCheckboxChange}
              disabled={item.value !== 'all' && allChecked}
            />
            <span className={!activeState && 'inactive'}>{item.label}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NotificationTypeCheckboxes;

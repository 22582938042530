import React, { LegacyRef, useEffect, useReducer, useState } from 'react';
import { useMutation } from 'react-query';

import { useClickOutside, useFeedbackHandler } from '+hooks';
import APIRequest from '+services/api-services';
import Modal from '+shared/Modal';
import { getDate, getTime, history } from '+utils';

import LoadingPlaceholder from '../Shared/LoadingPlaceHolder';
import NotificationTypeCheckboxes from './components/NotificationTypeCheckboxes';
import ToggleSwitch from './components/Toggleswitch';
import notificationTypesData from './data';

import ImgEmptyState from '+assets/img/dashboard/empty-state.png';
import SettingsIcon from '+assets/img/dashboard/settings-blue.svg';

import './index.scss';

export type NotificationT = {
  utilities: boolean;
  read: boolean;
  id: string;
  subject: string;
  body: string;
  createdAt: string;
};

export interface NotificationProps {
  isVisible: boolean;
  setIsVisible: (value: boolean) => void;
  content: NotificationT[];
  settings: Record<string, any>;
  handleRefetch: (paginate: boolean, read: boolean, isNotificationEnabled: boolean, otherParams?: Record<string, any> | undefined) => void;
  isLoading: boolean;
  notificationCount: number;
}

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

const Notifications: React.FC<NotificationProps> = ({
  isVisible,
  setIsVisible,
  content,
  settings,
  handleRefetch,
  isLoading,
  notificationCount
}) => {
  const { feedbackInit } = useFeedbackHandler();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [state, setState] = useReducer((prev: any, next: any) => ({ ...prev, ...next }), {
    selectedFilter: null,
    openNotificationSettings: false,
    hideReadNotification: false,
    isContent: false,
    isSwitchValue: true,
    clickedNotifications: [],
    notificationPeriod: '',
    notificationTypes: [],
    contentLength: 0
  });

  useEffect(() => {
    if (isFirstLoad) {
      setState({
        hideReadNotification: settings?.read,
        isSwitchValue: settings?.notificationEnabled,
        isContent: content?.length > 0,
        notificationPeriod: settings?.frequency || 'daily',
        notificationTypes: settings?.type || [],
        contentLength: notificationCount || 0
      });
      setIsFirstLoad(false);
    } else {
      setState({
        isSwitchValue: settings?.notificationEnabled,
        isContent: content?.length > 0,
        notificationPeriod: settings?.frequency || 'daily',
        notificationTypes: settings?.type || [],
        contentLength: notificationCount || 0
      });
    }
  }, [content, settings, isFirstLoad]);

  const handleFilterClick = (filterName: string) => {
    setState({ ...state, selectedFilter: filterName });
    if (filterName === 'all') {
      handleRefetch(false, !state.hideReadNotification, state.isSwitchValue, { frequency: state.notificationPeriod });
    } else {
      handleRefetch(false, !state.hideReadNotification, state.isSwitchValue, { frequency: state.notificationPeriod, type: filterName });
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ notificationPeriod: event.target.value });
  };

  const onToggleSwitchChange = (value: boolean) => {
    setState({ isSwitchValue: value });
  };

  const updateSettings: Record<string, any> = useMutation(updateData => api.upsertNotificationConfig(updateData), {
    onSuccess: () => {
      if (state.notificationTypes.includes('all') || state.notificationTypes === 'all') {
        handleRefetch(false, !state.hideReadNotification, state.isSwitchValue, { frequency: state.notificationPeriod });
      } else {
        handleRefetch(false, !state.hideReadNotification, state.isSwitchValue, {
          type: state.notificationTypes,
          frequency: state.notificationPeriod
        });
      }
    },
    onError: (error: string) => {
      feedbackInit({ message: error, componentLevel: true, type: 'danger' });
    }
  });

  const markAllAsRead = useMutation(() => api.markAllNotificationsAsRead(), {
    onSuccess: () => {
      handleRefetch(false, !state.hideReadNotification, state.isSwitchValue, {
        type: state.notificationTypes,
        frequency: state.notificationPeriod
      });
    },
    onError: (error: string) => {
      feedbackInit({ message: error, componentLevel: true, type: 'danger' });
    }
  });

  const markAsRead = useMutation((id: string) => api.markNotificationAsRead(id), {
    onSuccess: () => {
      handleRefetch(false, state.hideReadNotification, state.isSwitchValue, {
        type: state.notificationTypes,
        frequency: state.notificationPeriod
      });
    },
    onError: (error: string) => {
      feedbackInit({ message: error, componentLevel: true, type: 'danger' });
    }
  });

  const getNotificationTypes = (selectedValues: string[]) => {
    setState({ notificationTypes: selectedValues });
  };

  const notificationSettingsModalContent = () => {
    return (
      <div className="notification-modal-container">
        <div className="notification-modal">
          <div className="show-notification">
            <ToggleSwitch
              label="Show Notification"
              sublabel="Receive notification on events on the internal dashboard"
              isSwitchValue={state.isSwitchValue}
              onChange={onToggleSwitchChange}
            />
            <div className="notification-settings-checkbox">
              <input
                type="checkbox"
                checked={state.hideReadNotification}
                disabled={!state.isSwitchValue}
                onChange={e => {
                  setState({ hideReadNotification: e.target.checked });
                }}
              />
              <strong className={state.isSwitchValue ? '' : 'inactive'}>Hide read notifications</strong>
            </div>
          </div>
          <div className="divider" />
          <div className="notification-period">
            <p className="notification-period-title">Notification Period</p>
            <span className="notification-period-sublabel">
              Receive notification on daily, weekly or monthly events on the internal dashboard
            </span>
            <div className="notification-period-options">
              <div className="notification-period-option">
                <input
                  type="radio"
                  name="notification-period"
                  id="daily"
                  disabled={!state.isSwitchValue}
                  onChange={handleRadioChange}
                  value="daily"
                  checked={state.notificationPeriod === 'daily'}
                />
                <label htmlFor="daily" className={!state.isSwitchValue ? 'inactive' : ''}>
                  Last 24 hours
                </label>
              </div>
              <div className="notification-period-option">
                <input
                  type="radio"
                  name="notification-period"
                  id="weekly"
                  disabled={!state.isSwitchValue}
                  onChange={handleRadioChange}
                  value="weekly"
                  checked={state.notificationPeriod === 'weekly'}
                />
                <label htmlFor="weekly" className={!state.isSwitchValue ? 'inactive' : ''}>
                  Last 7 days
                </label>
              </div>
              <div className="notification-period-option">
                <input
                  type="radio"
                  name="notification-period"
                  id="monthly"
                  disabled={!state.isSwitchValue}
                  onChange={handleRadioChange}
                  value="monthly"
                  checked={state.notificationPeriod === 'monthly'}
                />
                <label htmlFor="monthly" className={state.isSwitchValue ? '' : 'inactive'}>
                  Last 30 days
                </label>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="notification-type">
            <p className="notification-type-title">Which notifications would you like to receive?</p>
            <NotificationTypeCheckboxes
              items={notificationTypesData}
              activeState={state.isSwitchValue}
              getNotificationTypes={getNotificationTypes}
              notificationTypes={state.notificationTypes}
            />
          </div>
        </div>
      </div>
    );
  };

  const wrapperRef = useClickOutside(() => {
    setIsVisible(false);
  });

  return (
    <>
      <div ref={wrapperRef as LegacyRef<HTMLDivElement>} className={`notification-sheet ${isVisible ? 'visible' : ''}`}>
        <div className="notification-sheet-title">
          <div className="title">
            <span>Notifications</span>
            {/* {state.isContent && <span>&nbsp;({state.contentLength} Unread)</span>} */}
          </div>
          <button type="button" onClick={() => setState({ openNotificationSettings: true })}>
            <img src={SettingsIcon} alt="settings" />
          </button>
        </div>
        <div className="notification-sheet-filter">
          {notificationTypesData.map(notificationType => (
            <button
              key={notificationType.value}
              className={
                notificationType.value === state.selectedFilter || state.notificationTypes.includes(notificationType.value)
                  ? 'selected'
                  : ''
              }
              type="button"
              onClick={() => handleFilterClick(notificationType.value)}
            >
              <span>{notificationType.label}</span>
            </button>
          ))}
        </div>
        {!state.isContent ? (
          <div className="no-notification" data-testid="no-notification">
            <img src={ImgEmptyState} alt="empty-state" />
            <p>
              There are no notifications on this
              <br />
              account at the moment.
            </p>
          </div>
        ) : (
          <div className="notification-content">
            {content && !isLoading ? (
              content.map((notification: NotificationT) => (
                <button
                  type="button"
                  onClick={() => {
                    const clickedNotifications = [...state.clickedNotifications];
                    clickedNotifications.push(notification.id);
                    setState({ clickedNotifications });
                    if (notification.utilities && !notification.read) {
                      history.push('/dashboard/utilities', {});
                      history.push('/dashboard/utilities', {});
                    }
                    markAsRead.mutateAsync(notification.id);
                  }}
                  key={notification.id}
                  className="notification-container"
                  style={{ background: notification.read || state.clickedNotifications.includes(notification.id) ? 'white' : '#F1F6FA' }}
                >
                  <div
                    className={
                      notification.read || state.clickedNotifications.includes(notification.id)
                        ? 'read-notification'
                        : 'unread-notification'
                    }
                  />
                  <div className="notification-item">
                    <p className="title">{notification.subject}</p>
                    <p className="message">{notification.body}</p>
                    <p className="date">{`${getDate(notification.createdAt)} at ${getTime(notification.createdAt)}`}</p>
                  </div>
                </button>
              ))
            ) : (
              <LoadingPlaceholder type="table" background="#f5f6f6" content={0} />
            )}
          </div>
        )}
        <div className="notification-bottom">
          <button
            className="read-text"
            style={{
              color: !state.isContent ? 'rgba(35, 118, 243, 0.3)' : '#2376F3',
              cursor: !state.isContent ? 'not-allowed' : 'pointer'
            }}
            type="button"
            onClick={() => markAllAsRead.mutateAsync()}
          >
            Mark all as read
          </button>
        </div>
      </div>
      <Modal
        description=""
        close={() => setState({ openNotificationSettings: false })}
        visible={state.openNotificationSettings}
        heading="Notification Preferences"
        size="md"
        secondButtonText="Save Preferences"
        content={notificationSettingsModalContent()}
        headerBottomBorder
        secondButtonAction={() => {
          const updateData = {
            config: {
              notificationEnabled: state.isSwitchValue,
              ...(state.isSwitchValue ? { frequency: state.notificationPeriod } : {}),
              ...(state.isSwitchValue ? { read: !state.hideReadNotification } : {}),
              ...(state.notificationTypes.includes('all') || state.notificationTypes === 'all' || !state.isSwitchValue
                ? {}
                : { type: state.notificationTypes })
            }
          };
          return updateSettings.mutateAsync(updateData);
        }}
        secondaryCompletedModal
        completedDescription="Your notification preferences have been saved"
      />
    </>
  );
};

export default Notifications;

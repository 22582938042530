/* eslint-disable import/prefer-default-export */
import CryptoJS from 'crypto-js';
import Filesaver from 'file-saver';
import { StateStorage } from 'zustand/middleware';

import { ValidationObject } from '+types/identity';
import { currencyOrder } from '+utils';

const key = `${process.env.REACT_APP_ZEUS_TRIDENT}`;

// API Downloading of files
export const APIDownload = (response: any, filename: string, format: string): void => {
  const blob = new Blob([response?.data], {
    type: response?.headers['content-type']
  });
  const downloadUrl = window.URL.createObjectURL(blob);
  return Filesaver.saveAs(downloadUrl, `${filename}.${format}`);
};

export const downloadFileViaUrl = async (url: string, fileName: string, contentType: string) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return Filesaver.saveAs(new Blob([blob], { type: contentType }), `${fileName}`);
};

export const defaultScrollToTop = () => document.body.scrollIntoView({ behavior: 'smooth', block: 'start' });

export const encryptContent = (value: any | null) => {
  if (!value) return null;
  return CryptoJS.AES.encrypt(JSON.stringify(value), key).toString();
};

export const decryptContent = (value: any | null) => {
  if (!value) return null;
  const bytes = CryptoJS.AES.decrypt(value, key);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const createParamsObject = (params: Record<string, any>) => {
  const allowedParams = ['page', 'limit', 'sortingParams', 'toExport', 'exportFormat', 'exportFields', 'id'];
  return allowedParams.map(param => (params[param] !== undefined ? params[param] : undefined));
};

export const isObjectNotEmpty = (obj: object | null | undefined) => {
  return ![null, undefined].includes(obj as null | undefined) && Object.keys(obj as object)?.length > 0;
};

export const maskFirstCharacters = (value: string, maskLength: number) => {
  const mask = '*'.repeat(maskLength);
  return `${mask}${value?.slice(maskLength)}`;
};

export const findMismatchKeys = (obj: ValidationObject, parentKey = ''): string[] => {
  let keys: string[] = [];
  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      keys = [...keys, ...findMismatchKeys(obj[key] as ValidationObject, parentKey ? `${parentKey}.${key}` : key)];
    } else if (key === 'match' && obj[key] === false) {
      keys.push(parentKey);
    }
  }
  return keys;
};

export const isObjectAllTrueOrAllFalse = (obj: { [key: string]: boolean }, checkForTrue: boolean): boolean => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] !== checkForTrue) {
        return false;
      }
    }
  }
  return true;
};

export const StoreStorage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await decryptContent(sessionStorage.getItem(name))) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await sessionStorage.setItem(name, encryptContent(value));
  },
  removeItem: async (name: string): Promise<void> => {
    await sessionStorage.removeItem(name);
  }
};

export const customTabOrder = (obj: { [key: string]: unknown } | string[], order: string[] = currencyOrder) => {
  const newCurrencyOrder: { [key: string]: unknown } | string[] = Array.isArray(obj) ? [] : {};

  order.forEach(e => {
    if (Array.isArray(obj) && obj.includes(e)) {
      (newCurrencyOrder as string[]).push(e);
    } else if (!Array.isArray(obj) && e in obj) {
      (newCurrencyOrder as { [key: string]: unknown })[e] = (obj as { [key: string]: unknown })[e];
    }
  });

  return newCurrencyOrder;
};

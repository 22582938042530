import { useEffect, RefObject, useRef } from 'react';

/**
 * Custom hook to handle clicks outside a specified element.
 * @param callback - The callback function to be called when a click occurs outside the element.
 * @returns A React ref that should be attached to the target element.
 * @example
 * // Example usage in a functional component:
 * const Component = () => {
 *   const wrapperRef = useClickOutside(() => {
 *     // Callback when a click occurs outside the element
 *     // Usually a setState
 *   });
 * 
 *   return (
 *     <div ref={wrapperRef}>
 *       //component content 
 *     </div>
 *   );
 * };
 */

const useClickOutside = <T extends HTMLElement>(
    callback: () => void
): RefObject<T> => {

    const ref = useRef<T>(null);

    const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            handleClickOutside(event);
        };

        document.addEventListener('click', handleDocumentClick, true);

        return () => {
            document.removeEventListener('click', handleDocumentClick, true);
        };
    }, [ref, callback]);

    return ref;
};

export default useClickOutside;


import React, { useRef, useState } from 'react';

import useBreakpoint from '+hooks/useBreakPoint';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import { APIDownload } from '+utils';

import FileViewer from './FileViewer';

import DownloadIcon from '+assets/img/dashboard/download.svg';

const api = new APIRequest();
const queries = {
  small: '(max-width: 450px)',
  normal: '(min-width: 700px)'
};

interface IViewAndDownloadProps {
  url: string;
  type: string;
  filename: string;
  info: string;
  disableView?: boolean;
  disableDownload?: boolean;
  showDownloadBtn?: boolean;
  downloadIcon?: React.ReactNode;
  previewIcon?: React.ReactNode;
  viewText?: string;
}
function ViewAndDownload({ url, type, filename, info, disableView, disableDownload, showDownloadBtn = true, viewText, downloadIcon, previewIcon }: IViewAndDownloadProps) {
  const { feedbackInit } = useFeedbackHandler();
  const [showView, setShowView] = useState(false);
  const matchPoints = useBreakpoint(queries);

  const download = async () => {
    try {
      const res = await api.downloadFile(url);
      APIDownload(res, `${info}: ${filename}`, type);

      feedbackInit({
        title: 'Download successful',
        message: (
          <>
            {' '}
            - Successfully downloaded{' '}
            <strong>
              {filename}.{type}
            </strong>
          </>
        ),
        type: 'success'
      });
    } catch (error) {
      feedbackInit({
        title: 'Download failed',
        message: `There has been an error in downloading your file`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <>
      {matchPoints?.small || type === 'pdf' ? (
        <a
          className="download_button"
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          download
          onClick={e => (disableView ? e.preventDefault() : null)}
          disabled={disableView}
        >
          {previewIcon}
          { viewText || 'View'}
        </a>
      ) : (
        <button type="button" className="download_button" href={url} onClick={() => setShowView(true)} disabled={disableView}>
          {previewIcon}
           { viewText || 'View'}
        </button>
      )}

      {showDownloadBtn && (
        <>
          <span className="divider-sm" />
          <button type="button" className="download_button" onClick={download} disabled={disableDownload}>
            {downloadIcon}
            <span>Download</span>
            {!downloadIcon && <img alt="" src={DownloadIcon} disabled={disableDownload} />}
          </button>
        </>
      )}

      <FileViewer url={url} showView={showView} setShowView={setShowView} />
    </>
  );
}

export default ViewAndDownload;

import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import ToolTip from '+shared/Tooltip';
import { TTransactionDetails, useTransactionStatusStateType } from '+types';
import { capitalize, isAllowed, payoutMethodMapping, switchStatus } from '+utils';

// eslint-disable-next-line import/prefer-default-export
export const payOutDetails = ({
  data,
  transactionType,
  updateTransactionStatusModalState,
  state,
  userAccess
}: {
  data: TTransactionDetails;
  transactionType: string;
  updateTransactionStatusModalState: (a: boolean, b: any) => void;
  state: useTransactionStatusStateType;
  userAccess: { [key: string]: boolean };
}) => {
  const {
    destination,
    reversal_reason,

    payment,
    status,

    processor_reference: processorReference
  } = data || {};

  return {
    status: (
      <>
        <span
          className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
          style={{ margin: '0 7px 5px 0' }}
        />
        {capitalize(status === 'requires_auth' ? 'Pending (Requires Authorization)' : status || 'Not Available')}
        {status === 'processing' &&
          ['mobile_money', 'bank_account'].includes(data?.payment_destination_type || data?.payment_source_type) &&
          !state.clickedTransactionIds?.includes(processorReference) &&
          isAllowed(userAccess, ['payouts_status.update']) && (
            <button
              aria-label="settings"
              onClick={e => {
                e.stopPropagation();
                updateTransactionStatusModalState(true, data);
              }}
              type="button"
              className="status-update-icon"
            >
              <Icon name="settings" />
            </button>
          )}
        {state.clickedTransactionIds?.includes(processorReference) && status === 'processing' && (
          <ToolTip
            type=""
            message={
              <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
            }
            centered
          >
            <span className="rotate">
              <Icon name="loading" />
            </span>
          </ToolTip>
        )}
      </>
    ),
    breakdown: {
      payment_method: payoutMethodMapping[destination?.type as unknown as keyof typeof payoutMethodMapping] || 'Not Available',
      ...(destination?.type === 'bank_account' && { bank_account: null }),
      ...(destination?.type === 'wallet' && { wallet: null }),
      ...(destination?.type === 'mobile_money' && { mobile_money: null }),
      recipient: capitalize(destination?.details?.account_name || payment?.customer?.name || 'Not Available'),
      email: payment?.customer?.email || 'Not provided',
      ...(status === 'success' && transactionType === 'refunds' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {})
    }
  };
};

import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import Icon from '+shared/Icons';
import { IError, IPlan } from '+types';
import { isAllowed, logError } from '+utils';
import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import PlanEditorModal from './components/PlanEditorModal';
import PlansTable from './components/PlansTable';
import './index.scss';
import { getTPVCollection } from './constants/plan-editor';

type SubtabType = 'reserved' | 'customer';

type ModalActionType = {
  type: 'edit' | 'add' | null;
  isOpen: boolean;
};

const subtabs: Array<{ name: string; id: SubtabType }> = [
  { name: 'Issued', id: 'customer' },
  { name: 'Reserved', id: 'reserved' }
];

const api = new APIRequest();

export default function CardIssuanceBilling() {
  const [modalAction, setModalAction] = useState<ModalActionType>({ type: null, isOpen: false });
  const [planId, setPlanId] = useState('');
  const { setQuery, value } = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const activeTab = (value?.subtab ?? subtabs[0].id) as 'reserved' | 'customer';

  const userAccess = useSetUserAccess();
  const canViewPlans = !!isAllowed(userAccess, ['card_issuance_plan_list.view']);
  const canUpdatePlans = !!isAllowed(userAccess, ['card_issuance_plan.update']);
  const canCreatePlan = !!isAllowed(userAccess, ['card_issuance_plan.create']);

  const data = useQuery<Array<IPlan>>(['BILLING_PLANS'], () => api.getPlans(), {
    onError: (error) => {
      logError(error);
      feedbackInit({
        message: (error as IError).response?.data?.message || "We're unable to retrieve plans at this time",
        type: 'danger',
        componentLevel: false
      });
    },
    enabled: canViewPlans
  });

  const selectedPlanToEdit = useMemo(() => {
    return data.data?.find((item) => item.reference === planId);
  }, [planId, data.data]);

  const handleTabChange = (tab: SubtabType) => {
    setQuery({ subtab: tab });
  };

  const handlePlanAdd = () => {
    setModalAction({ type: 'add', isOpen: true });
  };

  const handlePlanEdit = (id: string) => {
    setPlanId(id);
    setModalAction({ type: 'edit', isOpen: true });
  };

  const handleClose = () => {
    setModalAction({ type: null, isOpen: false });
  };

  return (
    <>
      <div className="stack-l">
        <div className="os-tabs-controls os-tabs-complex ">
          <ul className="nav nav-tabs">
            {subtabs.map((subtab) => (
              <li className="nav-item" key={subtab.id}>
                <button
                  type="button"
                  className={`nav-link ${activeTab === subtab.id ? 'active' : ''} tab-label`}
                  onClick={() => handleTabChange(subtab.id)}
                >
                  {subtab.name} Cards
                </button>
              </li>
            ))}
          </ul>
        </div>

        <section className="stack-l tab-panel">
          <h4 className="tab-panel-title text-dark-150">Subscription Plans</h4>

          <div className="box rounded-lg stack-l" data-bg="grey">
            <section className="text-dark-200 d-flex gap-md flex-wrap justify-content-between align-items-start">
              <div className="stack-m">
                <h5 className="box-title text-dark-200">Set up Subscription Plans for issuing merchants</h5>
                <p className="box-description text-dark-200">
                  Set the criteria and parameters for different Subscription Plans that are available to issuing merchants.
                </p>
              </div>

              <button
                onClick={handlePlanAdd}
                type="button"
                data-feat="plans"
                className="btn d-flex justify-content-center align-items-center"
                disabled={!canCreatePlan}
              >
                <Icon name="cross" width={17} height={17} /> Add new plan
              </button>
            </section>

            <PlansTable
              data={data?.data as Array<IPlan>}
              isDisabled={data?.isFetching || data?.isError || !canUpdatePlans}
              category={activeTab}
              onPlanEdit={handlePlanEdit}
            />
          </div>
        </section>
      </div>

      {modalAction.isOpen ? (
        <PlanEditorModal
          TPVCollection={getTPVCollection(data.data as IPlan[])}
          refetchPlans={data.refetch}
          cardCategory={activeTab}
          currentPlanData={selectedPlanToEdit}
          onClose={handleClose}
          action={modalAction.type as 'edit' | 'add'}
        />
      ) : null}
    </>
  );
}

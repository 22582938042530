import { capitalizeFirst } from '+utils';
import React, { useState } from 'react';
import { PlansType } from '../data';

const ExistingPlanModal = ({ merchantPlan }: { merchantPlan: PlansType }) => {
  const [activeFeeType, setActiveFeeType] = useState('issued');

  const handleFilterClick = (type: string) => {
    setActiveFeeType(type);
  };

  return (
    <>
      {Object.keys(merchantPlan).length > 1 && (
        <div className="view-plan-filter">
          <span>Currently viewing details for:</span>
          <div className="plan-filter">
            <button className={`${activeFeeType === 'issued' ? 'active' : ''}`} type="button" onClick={() => handleFilterClick('issued')}>
              Issued cards
            </button>
            <button
              className={`${activeFeeType === 'reserved' ? 'active' : ''}`}
              type="button"
              onClick={() => handleFilterClick('reserved')}
            >
              Reserved cards
            </button>
          </div>
        </div>
      )}
      <div className="view-billing-detail">
        <div className="billing-details">
          <p>Subscription Plan</p>
          <span>{capitalizeFirst(merchantPlan?.name)}</span>
        </div>
        <div className="billing-details">
          <p>Number of cards issuable</p>
          <span>{activeFeeType === 'issued' ? merchantPlan?.monthly_card_limit : merchantPlan?.reserved_card_limit}&nbsp;</span>
        </div>
        <div className="billing-details">
          <p>Subscription fee</p>
          <span>
            {merchantPlan?.fee?.[activeFeeType === 'issued' ? 'customer' : 'reserved']?.subscription?.amount}&nbsp;{merchantPlan?.currency}
          </span>
        </div>
        <div className="billing-details">
          <p>Card issuing fee</p>
          <span>
            {merchantPlan?.fee?.[activeFeeType === 'issued' ? 'customer' : 'reserved']?.issuance?.amount}&nbsp;{merchantPlan?.currency}
          </span>
        </div>
        <div className="billing-details">
          <p>Cross-currency fee</p>
          <span>
            {merchantPlan?.fee?.[activeFeeType === 'issued' ? 'customer' : 'reserved']?.cross_currency?.amount}&nbsp;
            {merchantPlan?.currency}
          </span>
        </div>
        <div className="billing-details">
          <p>Chargeback fee</p>
          <span>
            {merchantPlan?.fee?.[activeFeeType === 'issued' ? 'customer' : 'reserved']?.chargeback?.amount}&nbsp;{merchantPlan?.currency}
          </span>
        </div>
        <div className="billing-details">
          <p>Funding fee</p>
          <span>
            {merchantPlan?.fee?.[activeFeeType === 'issued' ? 'customer' : 'reserved']?.funding?.amount}&nbsp;{merchantPlan?.currency}
          </span>
        </div>
      </div>
    </>
  );
};

export default ExistingPlanModal;

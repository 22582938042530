import React from 'react';
import * as Flags from 'country-flag-icons/react/3x2';

import Icon from '+containers/Dashboard/Shared/Icons';
import { AccessRequestCountryT } from '+types';
import { regions } from '+utils';

export default function AccessRequestCountry({ country, useCase }: AccessRequestCountryT) {
  const Flag = Flags[country.toUpperCase()  as keyof typeof Flags];
  return (
    <div className="arc__country">
      <div className="arc__country__name">
        <div>
          <span>
            {Flag && <Flag />}
          </span>{' '}
          {regions[country as keyof typeof regions]}
        </div>
      </div>
      <div className="arc__country__use-case">
        {useCase.map((use, index) => (
          <div key={index} className="arc__country__use-case__item">
            <Icon name="check" width={12} height={12} /> {`ID Verification of ${use}`}
          </div>
        ))}
      </div>
    </div>
  );
}

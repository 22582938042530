import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import ViewAndDownload from '+containers/Dashboard/Shared/ViewAndDownload';

import './index.scss';

export default function DiligenceFormViewer({ url }: { url: string }) {
  if (!url) return null;
  const filetype = url?.split('.').pop();
  const fileName = url?.split('/').pop()?.split(`.${filetype}`)[0] || '';


  const formatFileName = (docName: string, type: string) => {
    if (docName?.length > 20) {
      const fileName = docName.substring(0, 20);
      return `${fileName}... ${type}`;
    }
    return `${docName}`;
  };


  return (
    <div className="diligence-form-viewer">
      <div className="diligence-form-viewer__header">Uploaded Due Diligence Form</div>
      <div className="diligence-form-viewer__file-wrapper">
        <div className="diligence-form-viewer__file-name">
          <Icon name="file" width={25} height={24} fill="#414F5F" />
          <span>
            {formatFileName(fileName, `.${filetype}`)}
          </span>
        </div>
        <div className="diligence-form-viewer__action-btns">
          <ViewAndDownload
            url={url}
            type="pdf"
            filename="file"
            showDownloadBtn
            info="Download"
            downloadIcon={<Icon name="outlineDownload" />}
            previewIcon={<Icon name="outlineCopy" />}
            viewText="Preview"
          />
        </div>
      </div>
    </div>
  );
}
